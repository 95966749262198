export const echoFilterByPanel = {
  name: 'FILTER BY',
  category: 'filterBy',
  filters: [
    {
      property: 'filterBy',
      name: 'Filter By',
      componentType: 'EchoFilterBy',
    },
  ],
}

export const echoSalesPanel = {
  name: 'SALES',
  category: 'sales',
  filters: [
    {
      property: 'sales',
      name: 'Sales',
      componentType: 'EchoSales',
    },
  ],
}

export const echoMapLayersPanel = {
  name: 'MAP LAYERS',
  category: 'mapLayers',
  filters: [
    {
      property: 'mapLayers',
      name: 'Map Layers',
      componentType: 'EchoMapLayer',
    },
  ],
}

export const echoProducersPanel = {
  name: 'PRODUCERS',
  category: 'producers',
  filters: [
    {
      property: 'producers',
      name: 'Producers',
      componentType: 'EchoProducer',
    },
  ],
}

export const echoProjectSearchPanel = {
  name: 'PROJECT SEARCH',
  category: 'projectSearch',
  filters: [
    {
      property: 'projectSearch',
      name: 'Project Search',
      componentType: 'EchoProjectFilters',
    },
  ],
}
