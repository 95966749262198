import React from 'react'
import { makeStyles, Typography, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined'
import { ownershipColors } from '../../../Constants/Constants'
import { useRecoilValue } from 'recoil'
import { atomFilterBySelected } from '../../../../Common/echoAtoms'

const useStyles = makeStyles({
  legendItemLabel: {
    fontSize: '0.9rem',
  },
  icon: {
    margin: '8px 0px',
    fontSize: '1.6rem',
  },
  customerColor: {
    color: ownershipColors.customers.hex,
  },
})

/** Function to return Customers icon for the map legend */
function CustomerLegend(props) {
  CustomerLegend.propTypes = {
    /** Filtered Echo data */
    filteredCorpData: PropTypes.array.isRequired,
  }
  const { filteredCorpData } = props
  const filterBySelected = useRecoilValue(atomFilterBySelected)
  const classes = useStyles()
  const checkIsClient = plant => plant.hasSystem === true
  if (
    filteredCorpData.length &&
    filteredCorpData.some(company =>
      company.divisions.some(division => division.plants.some(checkIsClient))
    )
  ) {
    return (
      <Grid item container lg={3}>
        <Grid container item lg={12}>
          <Grid item lg={2}>
            {filterBySelected ? (
              <FiberManualRecordOutlinedIcon
                className={`${classes.icon} ${classes.customerColor}`}
              />
            ) : (
              <FiberManualRecordIcon
                className={`${classes.icon} ${classes.customerColor}`}
              />
            )}
          </Grid>
          <Grid container item direction="column" lg={10} justify="center">
            <Typography className={classes.legendItemLabel}>
              Customers
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  } else return null
}

export default CustomerLegend
