import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { tabHeaders } from '../../Constants/Constants'
import RenderTablePresentational from './RenderTablePresentational'
import { useRecoilValue } from 'recoil'
import { atomEchoSettings } from '../../../Common/echoAtoms'
import { CO2DepotTableFeature } from '../../../Common/assets/FeatureFlags'
import { EMapLayers } from '../../Enums/EchoEnums'

RenderTableLogical.propTypes = {
  /** State setter function to keep track of which table rows have been selected */
  setSelectedRows: PropTypes.func.isRequired,
  /** The table rows that have been selected */
  selectedRows: PropTypes.array.isRequired,
  /** State setter to toggle loading spinner */
  setIsLoading: PropTypes.func.isRequired,
  /** Table page number */
  page: PropTypes.number.isRequired,
  /** State setter to update table page */
  setPage: PropTypes.func.isRequired,
  /** Ascending or descending sort order of table column */
  order: PropTypes.string.isRequired,
  /** State setter to update ascending or descending table sort order */
  setOrder: PropTypes.func.isRequired,
  /** Name of table column to sort by */
  orderBy: PropTypes.string.isRequired,
  /** State setter to update table column to sort by */
  setOrderBy: PropTypes.func.isRequired,
  /** Number of table rows to display */
  rowsPerPage: PropTypes.number.isRequired,
  /** State setter to update number of table rows to display */
  setRowsPerPage: PropTypes.func.isRequired,
  /** upcoming project data that has been returned after being filtered */
  filteredUpcomingProjects: PropTypes.array.isRequired,
  /** Array of data to display on the ready mix table. */
  readyMixTableData: PropTypes.array.isRequired,
  /** Array of data to display on the precast table. */
  precastTableData: PropTypes.array.isRequired,
  /** Boolean to display or not display loading spinner */
  isLoading: PropTypes.bool,
  /** Filtered and formatted array of CO2 vendors */
  formattedCO2VendorData: PropTypes.array.isRequired,
}

function RenderTableLogical(props) {
  /** Atom with Echo Settings **/
  const echoSettings = useRecoilValue(atomEchoSettings)
  const {
    setSelectedRows,
    selectedRows,
    setIsLoading,
    page,
    setPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    rowsPerPage,
    setRowsPerPage,
    filteredUpcomingProjects,
    readyMixTableData,
    precastTableData,
    isLoading,
    formattedCO2VendorData,
  } = props

  /** The value of the currently selected tab */
  const [tabValue, setTabValue] = useState(0)
  /** List of table tabHeaders to display */
  const [tableTabs, setTableTabs] = useState([{ label: 'Ready Mix', order: 0 }])
  /** List of selected map layers */
  const [selectedMapLayers, setSelectedMapLayers] = useState([])

  useEffect(() => {
    /** Track the map layers that are selected */
    setSelectedMapLayers(
      [
        echoSettings.layers.includes(EMapLayers.rmPlants) ||
        echoSettings.layers.includes(EMapLayers.rmCompanies)
          ? 'Ready Mix'
          : null,
        echoSettings.layers.includes(EMapLayers.precastPlants) ||
        echoSettings.layers.includes(EMapLayers.precastCompanies)
          ? 'Precast'
          : null,
        echoSettings.projects.includes('Upcoming Projects')
          ? 'Upcoming Projects'
          : null,
        echoSettings.projects.includes('Ongoing Projects')
          ? 'Ongoing Projects'
          : null,
        echoSettings.projects.includes('Finished Projects')
          ? 'Finished Projects'
          : null,
        echoSettings.layers.includes(EMapLayers.co2Depots) &&
        CO2DepotTableFeature
          ? 'CO₂ Depots'
          : null,
      ].filter(layer => layer !== null)
    )
  }, [echoSettings])

  /** Display a tab for each map layer selected */
  useEffect(() => {
    setTableTabs(selectedMapLayers.map(layer => tabHeaders[layer]))
  }, [echoSettings, selectedMapLayers])

  /** If more than one tab is added, switch over to the last one. */
  useEffect(() => {
    if (selectedMapLayers.length) {
      let layer = selectedMapLayers[selectedMapLayers.length - 1]
      setTabValue(tabHeaders[layer].order)
    }
  }, [echoSettings, selectedMapLayers])

  /** Change to a different tab when clicked */
  const handleChange = e => {
    let tab = e.target.closest('button').firstChild.firstChild.innerText
    setTabValue(value => {
      if (tab === 'Ready Mix') {
        return tabHeaders['Ready Mix'].order
      } else if (tab === 'Precast') {
        return tabHeaders['Precast'].order
      } else if (tab === 'Upcoming Projects') {
        return tabHeaders['Upcoming Projects'].order
      } else if (tab === 'Ongoing Projects') {
        return tabHeaders['Ongoing Projects'].order
      } else if (tab === 'Finished Projects') {
        return tabHeaders['Finished Projects'].order
      } else if (tab === 'CO₂ Depots') {
        return tabHeaders['CO₂ Depots'].order
      }
    })
  }

  return (
    <RenderTablePresentational
      tabValue={tabValue}
      handleChange={handleChange}
      tableTabs={tableTabs}
      setIsLoading={setIsLoading}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      filteredUpcomingProjects={filteredUpcomingProjects}
      page={page}
      setPage={setPage}
      order={order}
      setOrder={setOrder}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      readyMixTableData={readyMixTableData}
      precastTableData={precastTableData}
      isLoading={isLoading}
      formattedCO2VendorData={formattedCO2VendorData}
    />
  )
}

export default RenderTableLogical
