import React from 'react'
import { makeStyles, Typography, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined'
import { ownershipColors } from '../../../Constants/Constants'
import { useRecoilValue } from 'recoil'
import { atomFilterBySelected } from '../../../../Common/echoAtoms'

const useStyles = makeStyles({
  legendItemLabel: {
    fontSize: '0.9rem',
  },
  icon: {
    margin: '8px 0px',
    fontSize: '1.6rem',
  },
  verticallyIntegratedColor: {
    color: ownershipColors.verticallyIntegrated.hex,
  },
})

/** Function to return Vertically integrated icon for the map legend */
function VerticallyIntegratedLegend(props) {
  VerticallyIntegratedLegend.propTypes = {
    /** Filtered Echo data */
    filteredCorpData: PropTypes.array.isRequired,
    /** Selected map layers */
    mapLayers: PropTypes.array.isRequired,
  }
  const { filteredCorpData, mapLayers } = props
  const filterBySelected = useRecoilValue(atomFilterBySelected)
  const classes = useStyles()
  const checkVerticallyIntegrated = corporation =>
    corporation.isVerticallyIntegrated === true
  if (
    filteredCorpData.length &&
    filteredCorpData.some(checkVerticallyIntegrated) &&
    !mapLayers.includes('Project Search')
  ) {
    return (
      <Grid item container lg={3}>
        <Grid container item lg={12}>
          <Grid item lg={2}>
            {filterBySelected ? (
              <FiberManualRecordOutlinedIcon
                className={`${classes.icon} ${classes.verticallyIntegratedColor}`}
              />
            ) : (
              <FiberManualRecordIcon
                className={`${classes.icon} ${classes.verticallyIntegratedColor}`}
              />
            )}
          </Grid>
          <Grid container item direction="column" lg={10} justify="center">
            <Typography className={classes.legendItemLabel}>
              Vertically integrated
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  } else return null
}

export default VerticallyIntegratedLegend
