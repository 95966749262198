import { atom } from 'recoil'
import {
  IEchoSettings,
  IPlantMarker,
  IViewportSettings,
  ICarbonCureProject,
  IProjectSearchValues,
} from '../Echo/Interfaces/EchoInterfaces'
import { EMapLayers, EOwnershipFilterOptions } from '../Echo/Enums/EchoEnums'

/*
 * The atoms.js will contain all atoms for shared state.
 * As a general rule, only define an atom if a state needs to be shared between views.
 * Try and have states that are dependent on an endpoint/return value of a function state it in the jsdocs for that atom.
 * Below are two examples of how an atom should be documented.
 */

/**
 * Atom for TSS (and possibly in the future others) to keep track of the selected customer.
 *
 * initial structure:  {division: null, plant: null}
 *
 * Current customer should be an object with the shape of/include after being set:
 * {
 *  division: {
 *     divisionId: int,
 *     name: string,
 *     isImperial: bool
 *   },
 *  plant: {
 *     plantId: int,
 *     name: string
 *  }
 * }
 */
const atomEchoSettings = atom<IEchoSettings>({
  key: 'atomEchoSettings',
  default: {
    salesPeople: [],
    layers: [EMapLayers.rmPlants], // 'RM Plants', 'RM Companies', 'Precast Plants', 'Precast Companies', 'Cement Plants', 'CO₂ Depots', 'Opportunities'
    projects: [], // 'Upcoming Projects', 'Ongoing Projects', 'Finished Projects'
    countries: [],
    states: [],
    cities: [],
    groups: [],
    companies: [],
    zipCodes: [],
    addresses: [],
    ownership: [
      EOwnershipFilterOptions.privatelyOwned,
      EOwnershipFilterOptions.verticallyIntegrated,
      EOwnershipFilterOptions.customer,
    ],
    isGreenfield: false,
    isLoading: false,
    co2ConnectedToCustomers: false,
    plantsLimits: [],
    startDate: '',
    endDate: '',
    keywords: '',
  },
})

/** Array of options for the address search autocomplete */
const atomAddressOptions = atom<Array<string>>({
  key: 'atomAddressOptions',
  default: [],
})

/** Address search query */
const atomAddressSearch = atom<string>({
  key: 'atomAddressSearch',
  default: '',
})

/** Coordinates of address that has been searched in address filter */
const atomAddressCoordinates = atom<Array<number>>({
  key: 'atomAddressCoordinates',
  default: [],
})

/** Boolean to determine whether address options are loading in Autocomplete */
const atomLoadingAddresses = atom<boolean>({
  key: 'atomLoadingAddresses',
  default: false,
})

/** Choose to filter by country, state, city, group, company, zip code, or address */
const atomFilterGroup = atom<string>({
  key: 'atomFilterGroup',
  default: 'country',
})

/** Whether or not a "filter by" filter has been selected */
const atomFilterBySelected = atom<boolean>({
  key: 'atomFilterBySelected',
  default: false,
})

/** A flat array of plants */
const atomPlantMarkers = atom<Array<IPlantMarker>>({
  key: 'atomPlantMarkers',
  default: [],
})

/** Map viewport settings */
const atomMapViewportSettings = atom<IViewportSettings>({
  key: 'atomMapViewportSettings',
  default: {
    latitude: 10,
    longitude: -150,
    zoom: 1,
  },
})

/** Ongoing CarbonCure construction projects */
const atomFilteredOngoingProjects = atom<Array<ICarbonCureProject>>({
  key: 'atomFilteredOngoingProjects',
  default: [],
})

/** Finished CarbonCure construction projects */
const atomFilteredFinishedProjects = atom<Array<ICarbonCureProject>>({
  key: 'atomFilteredFinishedProjects',
  default: [],
})

const atomProjectSearchValues = atom<IProjectSearchValues>({
  key: 'atomProjectSearchValues',
  default: {
    projects: [], // 'Upcoming Projects', 'Ongoing Projects', 'Finished Projects'
    countries: [],
    states: [],
    cities: [],
    groups: [],
    companies: [],
    zipCodes: [],
    startDate: '',
    endDate: '',
    keywords: '',
  },
})

export {
  atomEchoSettings,
  atomAddressOptions,
  atomAddressSearch,
  atomAddressCoordinates,
  atomFilterGroup,
  atomLoadingAddresses,
  atomFilterBySelected,
  atomPlantMarkers,
  atomMapViewportSettings,
  atomFilteredOngoingProjects,
  atomFilteredFinishedProjects,
  atomProjectSearchValues,
}
