import {
  EchoTooltipBackground,
  getCementScoreColors,
} from '../../Logic/MapLogicHelpers'
import {
  translatedCementTypes,
  ownershipColors,
  radiuses,
  co2MarkerSizes,
  projectColors,
  opportunitiesColors,
} from '../../Constants/Constants'

/** location param is a plant or a company */
export function getLocationRadius(location, selectedIds) {
  return selectedIds.includes(location.corporationId)
    ? radiuses.selected
    : radiuses.notSelected
}

/** location param is a CO2 depot */
export function getCO2LocationSize(location, selectedRows) {
  const selectedStates = selectedRows.map(row => row.state)
  const selectedVendors = selectedRows.map(row => row.companyName)
  return selectedStates.includes(location.state) &&
    selectedVendors.includes(location.companyName)
    ? co2MarkerSizes.selected
    : co2MarkerSizes.noSelected
}

export function getUnfilteredCompanyFillColor(company, type) {
  switch (type) {
    case 'rmCompaniesNoCustomers':
    case 'precastCompaniesNoCustomers':
      if (!company.isVerticallyIntegrated) {
        return ownershipColors.privatelyOwned.rgb
      }
      if (company.isVerticallyIntegrated) {
        return ownershipColors.verticallyIntegrated.rgb
      }
      break
    case 'rmCompaniesCustomers':
    case 'precastCompaniesCustomers':
      if (company.someInstalled) {
        return ownershipColors.customers.rgb
      }
      break
    default:
  }
}

export function getNoCustomersPlantFill(plant, greenfield) {
  if (!greenfield) {
    return plant.isVerticallyIntegrated
      ? ownershipColors.verticallyIntegrated.rgb
      : ownershipColors.privatelyOwned.rgb
  }
  if (plant.someInstalled) {
    return ownershipColors.customers.rgb
  }
  if (!plant.isVerticallyIntegrated) {
    return ownershipColors.privatelyOwned.rgb
  }
  if (plant.isVerticallyIntegrated) {
    return ownershipColors.verticallyIntegrated.rgb
  }
}

export function getCustomersPlantFill(plant, greenfield) {
  if (!greenfield) {
    return ownershipColors.customers.rgb
  }
  if (plant.someInstalled) {
    return ownershipColors.customers.rgb
  }
  if (!plant.isVerticallyIntegrated) {
    return ownershipColors.privatelyOwned.rgb
  }
  if (plant.isVerticallyIntegrated) {
    return ownershipColors.verticallyIntegrated.rgb
  }
}

export function getUnfilteredPlantFillColor(plant, type, greenfield) {
  switch (type) {
    case 'rmPlantsNoCustomers':
    case 'precastPlantsNoCustomers':
      return getNoCustomersPlantFill(plant, greenfield)
    case 'rmPlantsCustomers':
    case 'precastPlantsCustomers':
      return getCustomersPlantFill(plant, greenfield)
    default:
  }
}

export function getPlantFill(plant, filterBySelected, type, greenfield) {
  return filterBySelected
    ? plant.uniqueColor.rgb
    : getUnfilteredPlantFillColor(plant, type, greenfield)
}

export function getCompanyFill(company, filterBySelected, type) {
  return filterBySelected
    ? company.uniqueColor.rgb
    : getUnfilteredCompanyFillColor(company, type)
}

/** Function that returns a string of cement info to display in a map marker tooltip
 * @param {Object} cement - A cement
 * @param {Boolean} concretePlant - Whether or not the cement info is going to be displayed on a concrete plant map marker
 * @returns {String} Returns a string of cement info to display in a map marker tooltip
 */
export const getCementInfo = (cement, concretePlant) => {
  let scoreValue = 'not yet tested'
  if (cement.score) {
    if (cement.score < 15) {
      scoreValue = 'low reactivity'
    } else if (cement.score < 60) {
      scoreValue = 'medium reactivity'
    } else if (cement.score > 60) {
      scoreValue = 'high reactivity'
    }
  }
  if (concretePlant && cement.cementType) {
    return `${translatedCementTypes[cement.cementType]} (${
      cement.cementPlantName
    }, ${scoreValue})\n`
  } else if (concretePlant) {
    return `${cement.cementName} (${cement.cementPlantName}, ${scoreValue})\n`
  } else if (cement.cementType) {
    return `${translatedCementTypes[cement.cementType]}, ${scoreValue}\n`
  } else {
    return `${cement.name}, ${scoreValue}\n`
  }
}

/** Function that returns a string indicating whether a concrete plant is Precast or Ready Mix (RM) */
export const getConcreteType = plant => {
  if (plant.isPrecast) return 'Precast'
  else return 'RM'
}

export function getOpportunityTooltipText(opportunity) {
  return `Sales Opportunity\nCompany: ${opportunity.companyName ??
    ''}\nStage: ${opportunity.pipelineStage ??
    ''}\nPlants: ${opportunity.plantCount ??
    ''}\nSales Person: ${opportunity.carbonCureSalesPerson ??
    ''}\nLocation: ${opportunity.city ?? ''}, ${opportunity.state ?? ''}`
}

export function getTooltip(object, echoSettings) {
  const type = object?.layerType ? object.layerType : '-'

  switch (type) {
    case 'carbonCureProjects':
      return {
        text: `${object.projectName}\nLocation: ${object.city}\nCustomer: ${
          object.customer
        }\nConcrete Delivered: ${object.concreteDelivered.toLocaleString()} yd3\nCO₂ Savings: ${
          object.co2Savings
        } tons\n Start date: ${object.startDate}\nStatus: ${
          object.projectStatus
        }`,
        style: {
          backgroundColor: '#C85842',
          color: '#FFFFFF',
        },
      }
    case 'upcomingProject':
      return {
        text: `${object.organization}\nLocation: ${object.formattedLocation}\nStart date: ${object.formattedStartDate}\nDescription: ${object.description}\nSize (m2): ${object.formattedSize}\nValue ($): ${object.formattedValue}\nStage: ${object.formattedStage}`,
        style: {
          backgroundColor: object.isCcSpecified
            ? projectColors.specified.hex
            : projectColors.unspecified.hex,
          color: '#FFFFFF',
        },
      }

    case 'company':
      return {
        text: `${getConcreteType(object)} Company\n${
          object.divisionName
        }\nState: ${object.state}\nPlants: ${object.numPlants}`,
        style: {
          backgroundColor: EchoTooltipBackground(object.layerType, object),
          color: '#FFFFFF',
        },
      }
    case 'plant':
      if (object.cements.length) {
        return {
          /** List cements used at the plant location if we have that information */
          text: `${getConcreteType(object)} Plant\n${
            object.divisionName
          }\nLocation: ${object.city}, ${
            object.state
          }\nCements:\n${object.cements
            .map(cement => {
              return getCementInfo(cement, true)
            })
            .join('')}
`,
          style: {
            backgroundColor: EchoTooltipBackground(
              object.layerType,
              object,
              echoSettings.isGreenfield
            ),
            color: '#FFFFFF',
          },
        }
      } else {
        return {
          text: `${getConcreteType(object)} Plant\n${
            object.divisionName
          }\nLocation: ${object.city}, ${object.state}
`,
          style: {
            backgroundColor: EchoTooltipBackground(
              object.layerType,
              object,
              echoSettings.isGreenfield
            ),
            color: '#FFFFFF',
          },
        }
      }
    case 'cementPlant':
      return {
        text: `${object.groupName}\n${object.companyName}\n${
          object.plantName
        }\nCements:\n${
          object.cements.length
            ? object.cements
                .map(cement => {
                  return getCementInfo(cement, false)
                })
                .join('')
            : ''
        }`,
        style: {
          backgroundColor: getCementScoreColors(object).hex,
          color: '#000000',
          borderRadius: '15px',
        },
      }
    case 'co2Depot':
      return {
        text: `CO₂ Depot\n${object.companyName}\nLocation: ${object.city}, ${object.state}`,
        style: {
          backgroundColor: '#0C1921',
          color: '#FFFFFF',
        },
      }
    case 'opportunity':
      return {
        text: getOpportunityTooltipText(object),
        style: {
          backgroundColor: opportunitiesColors.opportunities.hex,
          color: '#FFFFFF',
        },
      }
    default:
  }
}

/** All opportunity markers are red, but the opacity (alpha channel) is adjusted based on the sales pipeline stage. */
export function getOpportunityColourAndOpacity(opportunity) {
  switch (opportunity.pipelineStage) {
    case 'Presentation':
      return [200, 88, 66, 25]
    case 'Qualification':
      return [200, 88, 66, 51]
    case 'Proposal':
      return [200, 88, 66, 76]
    case 'Negotiation':
      return [200, 88, 66, 89]
    case 'Agreement':
      return [200, 88, 66, 102]
    case 'Goal':
      return [200, 88, 66, 114]
    case 'Decision Maker':
      return [200, 88, 66, 127]
    case 'In Discussion':
      return [200, 88, 66, 140]
    case 'Evaluation':
      return [200, 88, 66, 153]
    case 'Business Terms':
      return [200, 88, 66, 178]
    case 'Verbal':
      return [200, 88, 66, 216]
    default:
      return opportunitiesColors.opportunities.rgb
  }
}
