import React from 'react'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import MapLegend from '../MapLegend/MapLegend'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'

const useStyles = makeStyles({
  arrowIcon: {
    fontSize: '2rem',
    color: '#000000', // black
  },
  mapLegendButton: {
    width: '100%',
    textTransform: 'capitalize',
    padding: 'unset',
  },
  buttonLabel: {
    fontWeight: 'bold',
  },
})

function RenderMapLegend(props) {
  RenderMapLegend.propTypes = {
    /** Filtered Echo data */
    filteredCorpData: PropTypes.array.isRequired,
    /** Boolean to determine whether or not to display the map legend */
    showMapLegend: PropTypes.bool.isRequired,
    /** Function to show/hide the map legend */
    toggleMapLegend: PropTypes.func.isRequired,
  }
  const { filteredCorpData, showMapLegend, toggleMapLegend } = props

  const classes = useStyles()

  if (showMapLegend) {
    return (
      <>
        <Grid item container lg={11}>
          <MapLegend filteredCorpData={filteredCorpData} />
        </Grid>
        <Grid item lg={1}>
          <Button onClick={toggleMapLegend}>
            <KeyboardArrowUp className={classes.arrowIcon} />
          </Button>
        </Grid>
      </>
    )
  } else
    return (
      <>
        <Grid item lg={12}>
          <Button onClick={toggleMapLegend} className={classes.mapLegendButton}>
            <Grid item container lg={11}>
              <Typography variant="subtitle1" className={classes.buttonLabel}>
                Show Map Legend
              </Typography>
            </Grid>
            <Grid item lg={1}>
              <KeyboardArrowDown className={classes.arrowIcon} />
            </Grid>
          </Button>
        </Grid>
      </>
    )
}

export default RenderMapLegend
