import React from 'react'
import { makeStyles, Typography, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { atomEchoSettings } from '../../../Common/echoAtoms'
import CustomerLegend from './LegendItems/CustomerLegend'
import PrivatelyOwnedLegend from './LegendItems/PrivatelyOwnedLegend'
import VerticallyIntegratedLegend from './LegendItems/VerticallyIntegratedLegend'
import UpcomingProjectsLegend from './LegendItems/UpcomingProjectsLegend'
import OngoingProjectsLegend from './LegendItems/OngoingProjectsLegend'
import FinishedProjectsLegend from './LegendItems/FinishedProjectsLegend'
import CementPlantLegend from './LegendItems/CementPlantLegend'
import CO2DepotsLegend from './LegendItems/CO2DepotsLegend'
import OpportunitiesLegend from './LegendItems/OpportunitiesLegend'

const useStyles = makeStyles({
  legendTypeLabel: {
    fontWeight: 'bold',
  },
})

/** The map legend, displayed under the map */
function MapLegend(props) {
  MapLegend.propTypes = {
    /** Filtered Echo data */
    filteredCorpData: PropTypes.array.isRequired,
  }
  const { filteredCorpData } = props

  /** Atom with Echo Settings **/
  const echoSettings = useRecoilValue(atomEchoSettings)

  const classes = useStyles()

  return (
    <Grid container item>
      <Grid container item lg={12}>
        <Grid item container lg={3} direction="column" justify="center">
          <Typography className={classes.legendTypeLabel}>
            Concrete Producers:
          </Typography>
        </Grid>
        <CustomerLegend filteredCorpData={filteredCorpData} />
        <PrivatelyOwnedLegend
          filteredCorpData={filteredCorpData}
          mapLayers={echoSettings.layers}
        />
        <VerticallyIntegratedLegend
          filteredCorpData={filteredCorpData}
          mapLayers={echoSettings.layers}
        />
      </Grid>
      <CementPlantLegend echoSettings={echoSettings} />
      <UpcomingProjectsLegend echoSettings={echoSettings} />
      <OngoingProjectsLegend echoSettings={echoSettings} />
      <FinishedProjectsLegend echoSettings={echoSettings} />
      <CO2DepotsLegend echoSettings={echoSettings} />
      <OpportunitiesLegend echoSettings={echoSettings} />
    </Grid>
  )
}

export default MapLegend
