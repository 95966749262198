import { IconLayer } from '@deck.gl/layers'
import { getCementScoreColors } from '../../../Logic/MapLogicHelpers'

export function getCementPlantsLayer(filteredCementPlants) {
  const ICON_MAPPING = {
    marker: { x: 0, y: 0, width: 128, height: 128, mask: true },
  }

  return [
    new IconLayer({
      id: 'cementPlants',
      data: filteredCementPlants.map(plant => ({
        ...plant,
        layerType: 'cementPlant',
      })),
      pickable: true,
      iconAtlas: 'https://i.ibb.co/7Xj6n84/cement-plant-default.png',
      iconMapping: ICON_MAPPING,
      getIcon: plant => 'marker',
      getPosition: plant => [plant.longitude, plant.latitude],
      sizeScale: 15,
      getSize: 6,
      getColor: plant => getCementScoreColors(plant).rgb,
    }),
  ]
}
