import React from 'react'
import { makeStyles, Typography, Tooltip, Grid } from '@material-ui/core'
import { cementLegendTooltips } from '../../../Constants/Constants'
import PropTypes from 'prop-types'
import { EMapLayers } from '../../../Enums/EchoEnums'
import BusinessIcon from '@material-ui/icons/Business'
import { cementPlantsColors } from '../../../../theme/colors'

const useStyles = makeStyles({
  legendItem: {
    marginBottom: '0.5em',
  },
  legendTypeLabel: {
    fontWeight: 'bold',
  },
  legendItemLabel: {
    fontSize: '0.9rem',
  },
  cementPlantIcon: {
    width: '25px',
  },
  lowReactivity: {
    color: cementPlantsColors.lowReactivity.hex,
  },
  mediumReactivity: {
    color: cementPlantsColors.mediumReactivity.hex,
  },
  highReactivity: {
    color: cementPlantsColors.highReactivity.hex,
  },
  notTested: {
    color: cementPlantsColors.notTested.hex,
  },
})

function CementPlantLegend(props) {
  CementPlantLegend.propTypes = {
    /** The Echo settings atom */
    echoSettings: PropTypes.object.isRequired,
  }
  const { echoSettings } = props
  const classes = useStyles()

  if (echoSettings.layers.includes(EMapLayers.cementPlants)) {
    return (
      <Grid container item lg={12}>
        <Grid
          item
          container
          lg={3}
          direction="column"
          justify="center"
          className={classes.legendItem}
        >
          <Typography className={classes.legendTypeLabel}>
            Cement Plants:
          </Typography>
        </Grid>
        <Grid item container lg={3} className={classes.legendItem}>
          <Tooltip arrow title={cementLegendTooltips.high}>
            <Grid container alignItems="center">
              <Grid item lg={2}>
                <BusinessIcon
                  className={`${classes.cementPlanticon} ${classes.highReactivity}`}
                />
              </Grid>
              <Grid item lg={10}>
                <Typography className={classes.legendItemLabel}>
                  High Reactivity
                </Typography>
              </Grid>
            </Grid>
          </Tooltip>
        </Grid>
        <Grid item container lg={3} className={classes.legendItem}>
          <Tooltip arrow title={cementLegendTooltips.medium}>
            <Grid container alignItems="center">
              <Grid item lg={2}>
                <BusinessIcon
                  className={`${classes.cementPlanticon} ${classes.mediumReactivity}`}
                />
              </Grid>
              <Grid item lg={10}>
                <Typography className={classes.legendItemLabel}>
                  Medium Reactivity
                </Typography>
              </Grid>
            </Grid>
          </Tooltip>
        </Grid>
        <Grid item container lg={3} className={classes.legendItem}>
          <Tooltip arrow title={cementLegendTooltips.low}>
            <Grid container alignItems="center">
              <Grid item lg={2}>
                <BusinessIcon
                  className={`${classes.cementPlanticon} ${classes.lowReactivity}`}
                />
              </Grid>
              <Grid item lg={10}>
                <Typography className={classes.legendItemLabel}>
                  Low Reactivity
                </Typography>
              </Grid>
            </Grid>
          </Tooltip>
        </Grid>
        <Grid item container lg={3} className={classes.legendItem}></Grid>
        <Grid item container lg={3} className={classes.legendItem}>
          <Tooltip arrow title={cementLegendTooltips.notTested}>
            <Grid container alignItems="center">
              <Grid item lg={2}>
                <BusinessIcon
                  className={`${classes.cementPlanticon} ${classes.notTested}`}
                />
              </Grid>
              <Grid item lg={10}>
                <Typography className={classes.legendItemLabel}>
                  Not Tested
                </Typography>
              </Grid>
            </Grid>
          </Tooltip>
        </Grid>
      </Grid>
    )
  } else return null
}

export default CementPlantLegend
