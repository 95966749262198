import React from 'react'
import { makeStyles, Typography, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { CloudOutlined } from '@material-ui/icons'
import { EMapLayers } from '../../../Enums/EchoEnums'
import { echoColors } from '../../../../theme/colors'

const useStyles = makeStyles({
  legendTypeLabel: {
    fontWeight: 'bold',
  },
  legendItemLabel: {
    fontSize: '0.9rem',
  },
  icon: {
    margin: '8px 0px',
    fontSize: '1.6rem',
  },
  co2Color: {
    color: echoColors.co2Deport.hex, // black
  },
})

/** Function to return CO2 depots icon for the map legend */
function CO2DepotsLegend(props) {
  CO2DepotsLegend.propTypes = {
    /** The Echo settings atom */
    echoSettings: PropTypes.object.isRequired,
  }
  const { echoSettings } = props
  const classes = useStyles()
  if (echoSettings.layers.includes(EMapLayers.co2Depots)) {
    return (
      <Grid container item lg={12}>
        <Grid item container lg={3} direction="column" justify="center">
          <Typography className={classes.legendTypeLabel}>
            CO₂ Depots:
          </Typography>
        </Grid>
        <Grid item container lg={9}>
          <Grid container item lg={4}>
            <Grid item lg={2}>
              <CloudOutlined
                className={`${classes.icon} ${classes.co2Color}`}
              />
            </Grid>
            <Grid container item direction="column" lg={10} justify="center">
              <Typography className={classes.legendItemLabel}>
                CO₂ Depots
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  } else return null
}

export default CO2DepotsLegend
