import { uniqueColors, ownershipColors } from '../Constants/Constants'
import { checkIsWithinRadius } from './FilterLogicHelpers'
import { cementPlantsColors } from '../../theme/colors'

/**
 * Update plant map marker tooltip background colour to match map marker colour
 * @param {Plant} location
 * @param {Boolean} greenfield
 */
export const plantTooltipBackground = (location, greenfield) => {
  if (!greenfield && location.hasSystem) {
    return ownershipColors.customers.hex
  }
  if (!greenfield && !location.hasSystem && !location.isVerticallyIntegrated) {
    return ownershipColors.privatelyOwned.hex
  }
  if (!greenfield && !location.hasSystem && location.isVerticallyIntegrated) {
    return ownershipColors.verticallyIntegrated.hex
  }
  if (greenfield && location.someInstalled) {
    return ownershipColors.customers.hex
  }
  if (
    greenfield &&
    !location.someInstalled &&
    !location.isVerticallyIntegrated
  ) {
    return ownershipColors.privatelyOwned.hex
  }
  if (
    greenfield &&
    !location.someInstalled &&
    location.isVerticallyIntegrated
  ) {
    return ownershipColors.verticallyIntegrated.hex
  }
}

/**
 * Update company map marker tooltip background colour to match map marker colour
 * @param {Division} location
 */
export const companyTooltipBackground = location => {
  if (location.someInstalled) {
    return ownershipColors.customers.hex
  }
  if (!location.someInstalled && !location.isVerticallyIntegrated) {
    return ownershipColors.privatelyOwned.hex
  }
  if (!location.someInstalled && location.isVerticallyIntegrated) {
    return ownershipColors.verticallyIntegrated.hex
  }
}

/**
 * Update Echo map tooltip background colour to match map marker colour
 * @param {'plant' | 'company'} layerType
 * @param {Division | Plant} location
 * @param {Boolean} greenfield
 */
export const EchoTooltipBackground = (layerType, location, greenfield) => {
  if (layerType === 'plant') {
    return plantTooltipBackground(location, greenfield)
  }
  if (layerType === 'company') {
    return companyTooltipBackground(location)
  }
}

/** Function that creates a flat array of plants, to be used for the plant map markers. */
export const flatPlants = data => {
  const randomBuffer = new Uint32Array(1)
  const randomValue = window.crypto.getRandomValues(randomBuffer)[0]
  let randomNumber = randomValue / (0xffffffff + 1)
  const randomFloat = randomNumber
  const flatArray = []
  data.forEach(corporation => {
    corporation.uniqueColor =
      uniqueColors[Math.floor(randomFloat * uniqueColors.length)]
    corporation.divisions.forEach(division => {
      division.plants.forEach(plant => {
        flatArray.push({
          ...plant,
          corporationId: corporation.corporationId,
          corporationName: corporation.corporationName,
          isPrecast: plant.isPrecast,
          isVerticallyIntegrated: corporation.isVerticallyIntegrated,
          divisionName: division.divisionName,
          hasSystem: plant.hasSystem,
          someInstalled: division.plants.some(location => location.hasSystem),
          salesPerson: plant.salesperson,
          city: plant.city,
          state: plant.state,
          country: plant.country,
          uniqueColor: corporation.uniqueColor,
        })
      })
    })
  })
  return flatArray
}

/** Function that creates a flat array of companies, to be used for the company map markers. */
export const flatCompanies = data => {
  const flatArray = []
  data.forEach(corporation => {
    corporation.divisions.forEach(division => {
      division.states.forEach(state => {
        flatArray.push({
          corporationId: corporation.corporationId,
          isVerticallyIntegrated: corporation.isVerticallyIntegrated,
          divisionName: division.divisionName,
          someInstalled: division.plants.some(plant => plant.hasSystem),
          isPrecast: division.plants.some(plant => plant.isPrecast),
          state: state.state,
          stateLong: state.longitude,
          stateLat: state.latitude,
          numPlants: division.plants.length,
          uniqueColor: corporation.uniqueColor,
        })
      })
    })
  })
  return flatArray
}

/** Function to get map marker outline colour for plant locations when the greenfield toggle is turned on.
 * @param {Object} division - a division from the plantMarkers array
 * @returns {Array} - a colour in rgb format.
 */
export const getGreenfieldPlantLineColor = division => {
  const privatelyOwned =
    !division.someInstalled && !division.isVerticallyIntegrated
  const verticallyIntegrated =
    !division.someInstalled && division.isVerticallyIntegrated
  if (division.someInstalled) {
    return ownershipColors.customers.rgb
  }
  if (privatelyOwned) {
    return ownershipColors.privatelyOwned.rgb
  }
  if (verticallyIntegrated) {
    return ownershipColors.verticallyIntegrated.rgb
  }
}

/** Function to get map marker outline colour for plant locations when the greenfield toggle is turned off.
 * @param {Object} plant - a plant from the plantMarkers array
 * @returns {Array} - a colour in rgb format.
 */
export const getNonGreenfieldPlantLineColor = plant => {
  if (plant.hasSystem) {
    return ownershipColors.customers.rgb
  }
  /** If greenfield toggle is off, colour each plant outline according to whether it is a customer, vertically integrated, or privately owned. */
  if (!plant.isVerticallyIntegrated) {
    return ownershipColors.privatelyOwned.rgb
  }
  if (plant.isVerticallyIntegrated) {
    return ownershipColors.verticallyIntegrated.rgb
  }
}

/** Function to get map marker outline colour for plant locations
 * @param {Boolean} greenfield - whether or not the greenfield toggle is turned on.
 * @returns {String} - a colour in rgb format.
 */
export const getPlantLineColor = greenfield => {
  return plant => {
    if (greenfield) {
      return getGreenfieldPlantLineColor(plant)
    } else {
      return getNonGreenfieldPlantLineColor(plant)
    }
  }
}

/** Function to get map marker outline colour for company locations */
export const getCompanyLineColor = () => {
  return company => {
    if (company.someInstalled) {
      return ownershipColors.customers.rgb
    }
    if (!company.isVerticallyIntegrated) {
      return ownershipColors.privatelyOwned.rgb
    }
    if (company.isVerticallyIntegrated) {
      return ownershipColors.verticallyIntegrated.rgb
    }
  }
}

/** Return an RGB colour and a hex colour according to cement reactivity score */
export const getCementScoreColors = cementPlant => {
  const filteredCements = cementPlant.cements.filter(
    cement => cement.score != null
  )
  let primaryCement
  if (filteredCements.length > 0) {
    if (
      filteredCements.map(cement => cement.cementType).includes('CementTypeI')
    ) {
      primaryCement = filteredCements.find(
        cement => cement.cementType === 'CementTypeI'
      )
    } else if (
      filteredCements
        .map(cement => cement.cementType)
        .includes('CementTypeI_II')
    ) {
      primaryCement = filteredCements.find(
        cement => cement.cementType === 'CementTypeI_II'
      )
    } else {
      primaryCement = filteredCements[0]
    }
    if (primaryCement.score >= 60) {
      return cementPlantsColors.highReactivity
    } else if (primaryCement.score >= 15) {
      return cementPlantsColors.mediumReactivity
    } else if (primaryCement.score < 15) {
      return cementPlantsColors.lowReactivity
    }
  } else return cementPlantsColors.notTested
}

/** Allow only one type of RM layer and one type of Precast layer to be displayed at a time (ex. only display one of RM Plants/RM Companies)
 * @param {String} option - a map layer dropdown option
 * @param {Array} selectedOptions - selected map layers
 * @returns {Boolean} - Whether or not a layer is incompatible with an already-selected layer, and should therefore be disabled.
 */
export const isIncompatibleLayer = (option, selectedOptions) => {
  return selectedOptions.some(
    selectedOption =>
      (selectedOption.includes('RM') && option.includes('RM')) ||
      (selectedOption.includes('Precast') && option.includes('Precast'))
  )
}

/** Function to disable certain map layer options
 * @param {String} option - a map layer dropdown option
 * @param {Array} selectedOptions - selected map layers
 * @returns {Boolean} - Whether or not a layer is incompatible with an already-selected layer, and should therefore be disabled.
 */
export const disableMapLayerOptions = (option, selectedOptions) => {
  return (
    isIncompatibleLayer(option, selectedOptions) ||
    (selectedOptions.includes('Project Search') &&
      (option === 'Cement Plants' ||
        option === 'CO₂ Depots' ||
        option === 'Opportunities'))
  )
}

/** Filters the array of plantMarkers based on the echoSettings atom */
export const getFilteredPlantMarkers = (plantMarkers, echoSettings) => {
  let result = plantMarkers
  // salesPeople
  if (echoSettings.salesPeople.length) {
    result = result.filter(
      plant => plant.salesPerson === echoSettings.salesPeople[0]
    )
  }
  // countries
  if (echoSettings.countries.length) {
    result = result.filter(plant => plant.country === echoSettings.countries[0])
  }
  // states
  if (echoSettings.states.length) {
    result = result.filter(plant => echoSettings.states.includes(plant.state))
  }
  // cities
  if (echoSettings.cities.length) {
    result = result.filter(plant =>
      checkIsWithinRadius(plant.longLat, echoSettings.cities[0].longLat, 200)
    )
  }
  // companies
  if (echoSettings.companies.length) {
    result = result.filter(
      plant => plant.divisionName === echoSettings.companies[0]
    )
  }
  result = result.map(plant => ({ ...plant, layerType: 'plant' }))
  return result
}
