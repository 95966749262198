import { haversineDistance } from './GeneralEchoLogicHelpers'
import cloneDeep from 'lodash.clonedeep'
import { EMapLayers } from '../Enums/EchoEnums'

/** Function to check if cityFilter is active */
export const isFilterByCityActive = filterByCity => {
  return filterByCity && Object.keys(filterByCity).length
}

/** Function to check if any of country/state/city/group/company/zip code filter is active */
export const isAnyFilterActive = (echoSettings, zipCodeCoordinates) => {
  return !!(
    echoSettings.countries.length ||
    echoSettings.states.length ||
    echoSettings.cities.length ||
    echoSettings.groups.length ||
    echoSettings.companies.length ||
    zipCodeCoordinates.length
  )
}

/** If any of the ownership filters are unchecked (Customer/Privately owned/Vertically integrated) filter the corpData accordingly.
 * @param {Object} echoSettings - the echoSettings atom
 * @param {Function} setFilteredCorpData - state setter to update the filteredCorpData array
 */
export const handleOwnershipFilter = (echoSettings, setFilteredCorpData) => {
  /** If customer checkbox is not checked, remove corporations that do not have any plants using CarbonCure */
  if (!echoSettings.ownership.includes('customer')) {
    setFilteredCorpData(data =>
      data.filter(company =>
        company.divisions.every(division =>
          division.plants.every(plant => !plant.hasSystem)
        )
      )
    )
  }
  /** If privately owned checkbox is not checked, only display corporations that are either vertically integrated or that have some plants with CarbonCure installed. */
  if (!echoSettings.ownership.includes('privatelyOwned')) {
    const returnNotPrivatelyOwned = company =>
      company.isVerticallyIntegrated ||
      company.divisions.some(division =>
        division.plants.some(plant => plant.hasSystem)
      )
    setFilteredCorpData(data =>
      data.filter(company => returnNotPrivatelyOwned(company))
    )
  }
  /** If vertically integrated checkbox is not checked, only display corporations that are either privately owned or that have some plants with CarbonCure installed. */
  if (!echoSettings.ownership.includes('verticallyIntegrated')) {
    const returnNotVerticallyIntegrated = company =>
      !company.isVerticallyIntegrated ||
      company.divisions.some(division =>
        division.plants.some(plant => plant.hasSystem)
      )
    setFilteredCorpData(data =>
      data.filter(company => returnNotVerticallyIntegrated(company))
    )
  }
}

/** Check which data needs updating based on which filters have been used.
 * @param {Object} echoSettings - the echoSettings atom
 * @param {Array} zipCodeCoordinates - array of coordinates for the searched zip code
 * @param {Array} addressCoordinates - array of coordinates for the searched address
 * @returns {Array} - list of Echo data types to update
 */
export const whatToUpdate = (
  echoSettings,
  zipCodeCoordinates,
  addressCoordinates
) => {
  let dataToUpdate = []
  if (echoSettings.salesPeople.length > 0 || echoSettings.plantsLimits.length) {
    dataToUpdate.push(...['filteredCorpData', 'filteredSalesOpportunities'])
  }
  if (
    !echoSettings.ownership.length < 3 ||
    echoSettings.groups.length > 0 ||
    echoSettings.companies.length > 0 ||
    zipCodeCoordinates.length
  ) {
    dataToUpdate.push(...['filteredCorpData'])
  }
  if (echoSettings.countries.length > 0) {
    return [
      'filteredCorpData',
      'filteredUpcomingProjects',
      'filteredOngoingProjects',
      'filteredFinishedProjects',
      'filteredCementPlants',
      'filteredCO2Depots',
      'filteredSalesOpportunities',
    ]
  }
  if (echoSettings.states.length > 0) {
    dataToUpdate.push(
      ...[
        'filteredCorpData',
        'filteredUpcomingProjects',
        'filteredOngoingProjects',
        'filteredFinishedProjects',
        'filteredCementPlants',
        'filteredCO2Depots',
        'filteredCO2Vendors',
        'filteredSalesOpportunities',
      ]
    )
  }
  if (echoSettings.cities.length > 0) {
    dataToUpdate.push(
      ...[
        'filteredCorpData',
        'filteredUpcomingProjects',
        'filteredOngoingProjects',
        'filteredFinishedProjects',
        'filteredCementPlants',
        'filteredCO2Depots',
        'filteredSalesOpportunities',
      ]
    )
  }
  if (addressCoordinates.length) {
    dataToUpdate.push(...['filteredCO2Depots'])
  }
  if (
    echoSettings.startDate.length > 0 ||
    echoSettings.endDate.length > 0 ||
    echoSettings.keywords.length > 0
  ) {
    dataToUpdate.push(
      ...[
        'filteredUpcomingProjects',
        'filteredOngoingProjects',
        'filteredFinishedProjects',
      ]
    )
  }
  return [...new Set(dataToUpdate)]
}

/** Check whether an object has the number of plants within the min/max number of plants selected on the slider.
 * @param {Object} object - a corporation or sales opportunity
 * @param {Object} echoSettings - the echoSettings atom
 * @returns {Boolean} - Whether or not an object has the number of plants within the min/max number of plants selected on the slider
 */
export const isWithinPlantLimits = (object, echoSettings) =>
  object.plantCount >= echoSettings.plantsLimits[0] &&
  object.plantCount <= echoSettings.plantsLimits[1]

/** Update the filteredCorpData array as settings are changed
 * @param {Function} setFilteredCorpData - state setter to update the filteredCorpData array
 * @param {Object} echoSettings - the echoSettings atom
 * @param {Array} zipCodeCoordinates - array of coordinates for the searched zip code
 */
export const updateCorpData = (
  setFilteredCorpData,
  echoSettings,
  zipCodeCoordinates
) => {
  /** If a sales person has been selected, filter data to show corporations with divisions that have some plants assigned to that sales person */
  if (echoSettings.salesPeople.length > 0) {
    const checkSalesPerson = obj =>
      obj.salesperson === echoSettings.salesPeople[0]
    setFilteredCorpData(data =>
      data.filter(company =>
        company.divisions.some(division =>
          division.plants.some(checkSalesPerson)
        )
      )
    )
  }
  /** If any of the ownership filters are unchecked (Customer/Privately owned/Vertically integrated) filter the corpData accordingly */
  if (echoSettings.ownership.length < 3) {
    handleOwnershipFilter(echoSettings, setFilteredCorpData)
  }
  /** If a country is selected to filter by country, return only divisions or plants located in that country */
  if (echoSettings.countries.length > 0) {
    const checkCountry = obj => obj.country === echoSettings.countries[0]
    const returnDivisionsWithCountry = division =>
      division.countries.some(checkCountry) ||
      division.plants.some(checkCountry)
    setFilteredCorpData(data =>
      data.filter(company =>
        company.divisions.some(division => returnDivisionsWithCountry(division))
      )
    )
  }
  /** If a state is selected to filter by state, return only divisions/plants/projects/depots located in that state */
  if (echoSettings.states.length > 0) {
    const checkState = obj => echoSettings.states.includes(obj.state)
    const returnDivisionsWithStates = division =>
      division.states.some(checkState) || division.plants.some(checkState)
    setFilteredCorpData(data =>
      data.filter(company =>
        company.divisions.some(division => returnDivisionsWithStates(division))
      )
    )
  }
  /** If a city is selected to filter by city, return only plants/projects located within 200 km of that city */
  if (echoSettings.cities.length > 0) {
    setFilteredCorpData(data => {
      return data.filter(company =>
        company.divisions.some(division =>
          division.plants.some(plant =>
            checkIsWithinRadius(
              plant.longLat,
              echoSettings.cities[0].longLat,
              200
            )
          )
        )
      )
    })
  }
  /** If a group is selected to filter by group, return only divisions from that group/corporation */
  if (echoSettings.groups.length > 0) {
    setFilteredCorpData(data =>
      data.filter(
        company => company.corporationName === echoSettings.groups[0].label
      )
    )
  }
  /** If a company is selected to filter by company, return only divisions for that company/corporation */
  if (echoSettings.companies.length > 0) {
    const checkIsSelectedCompany = obj =>
      obj.divisionName === echoSettings.companies[0]
    setFilteredCorpData(data =>
      data.filter(company => company.divisions.some(checkIsSelectedCompany))
    )
  }
  /** If a zip code is selected to filter by zip code, filter out results that are not within 200km of the center of that zip code. */
  if (zipCodeCoordinates.length) {
    setFilteredCorpData(data => {
      return data.filter(company =>
        company.divisions.some(division =>
          division.plants.some(plant =>
            checkIsWithinRadius(plant.longLat, zipCodeCoordinates, 200)
          )
        )
      )
    })
  }
  /** When slider value is changed, display corporations and opportunities with a plantCount within the minimum and maximum number of plants indicated on the slider */
  if (echoSettings.plantsLimits.length) {
    setFilteredCorpData(data =>
      data.filter(company => isWithinPlantLimits(company, echoSettings))
    )
  }
}

/** Filter an array of endpoint data by state
 * @param {Array} endpointData - an array of data from a Kelowna endpoint
 * @param {Object} echoSettings - the echoSettings atom
 * @returns {Array} - array of endpoint data, filtered by state
 */
export const filterEndpointDataByState = (endpointData, echoSettings) =>
  endpointData.filter(data => echoSettings.states.includes(data.state))

/** Filter an array of locations to only display locations within a 200 km radius of a selected city
 * @param {Array} locations - an array of locations
 * @param {Object} echoSettings - the echoSettings atom
 * @returns {Array} - array of locations, filtered to only display locations within a 200 km radius of a selected city
 */
export const radiusCityFilter = (locations, echoSettings) => {
  return locations.filter(marker =>
    checkIsWithinRadius(
      [marker.longitude, marker.latitude],
      echoSettings.cities[0].longLat,
      200
    )
  )
}

/** Function to update the array of sales opportunities
 * @param {Function} setFilteredSalesOpportunities - state setter to update the array of filteredSalesOpportunities
 * @param {Object} echoSettings - the echoSettings atom
 */
export const updateSalesOpportunities = (
  setFilteredSalesOpportunities,
  echoSettings
) => {
  /** If a sales person has been selected, filter data to show corporations with divisions that have some plants assigned to that sales person */
  if (echoSettings.salesPeople.length > 0) {
    setFilteredSalesOpportunities(opportunities =>
      opportunities.filter(
        opportunity =>
          opportunity.carbonCureSalesPerson === echoSettings.salesPeople[0]
      )
    )
  }
  /** If a country is selected to filter by country, return only divisions or plants located in that country */
  if (echoSettings.countries.length > 0) {
    setFilteredSalesOpportunities(opportunities =>
      opportunities.filter(
        opportunity => opportunity.country === echoSettings.countries[0]
      )
    )
  }
  /** If a state is selected to filter by state, return only divisions/plants/projects/depots located in that state */
  if (echoSettings.states.length > 0) {
    setFilteredSalesOpportunities(opportunities =>
      filterEndpointDataByState(opportunities, echoSettings)
    )
  }
  /** If a city is selected to filter by city, return only plants/projects located within 200 km of that city */
  if (echoSettings.cities.length > 0) {
    setFilteredSalesOpportunities(opportunities =>
      radiusCityFilter(opportunities, echoSettings)
    )
  }
  /** When slider value is changed, display corporations and opportunities with a plantCount within the minimum and maximum number of plants indicated on the slider */
  if (echoSettings.plantsLimits.length) {
    setFilteredSalesOpportunities(opportunities =>
      opportunities.filter(opportunity =>
        isWithinPlantLimits(opportunity, echoSettings)
      )
    )
  }
}

/** Filter projects using the keyword search
 * @param {Array} projects - array of projects to filter
 * @param {Object} echoSettings - the echoSettings atom
 * @returns {Array} the filtered project array
 */
export const projectKeywordFilter = (projects, echoSettings) => {
  return projects.filter(project =>
    JSON.stringify(project)
      .toLowerCase()
      .includes(echoSettings.keywords.toLowerCase())
  )
}

/** Function to update the array of upcoming projects
 * @param {Function} setFilteredUpcomingProjects - state setter to update the array of filteredUpcomingProjects
 * @param {Object} echoSettings - the echoSettings atom
 */
export const updateUpcomingProjects = (
  setFilteredUpcomingProjects,
  echoSettings
) => {
  /** If a country is selected to filter by country, return only divisions or plants located in that country */
  if (echoSettings.countries.length > 0) {
    setFilteredUpcomingProjects(projects =>
      projects.filter(project => project.country === echoSettings.countries[0])
    )
  }
  /** If a state is selected to filter by state, return only divisions/plants/projects/depots located in that state */
  if (echoSettings.states.length > 0) {
    setFilteredUpcomingProjects(projects =>
      filterEndpointDataByState(projects, echoSettings)
    )
  }
  /** If a city is selected to filter by city, return only plants/projects located within 200 km of that city */
  if (echoSettings.cities.length > 0) {
    setFilteredUpcomingProjects(projects =>
      radiusCityFilter(projects, echoSettings)
    )
  }
  /** If a start date is selected, filter out projects with a start date before the selected start date. */
  if (echoSettings.startDate.length > 0) {
    setFilteredUpcomingProjects(projects =>
      projects.filter(
        project => echoSettings.startDate <= project.targetStartDate
      )
    )
  }
  /** If an end date is selected, filter out projects with a start date after the selected end date. */
  if (echoSettings.endDate.length > 0) {
    setFilteredUpcomingProjects(projects =>
      projects.filter(
        project => echoSettings.endDate >= project.targetStartDate
      )
    )
  }
  /** If key words are entered into the keyword filter, return only projects that contain the keyword text. */
  if (echoSettings.keywords.length > 0) {
    setFilteredUpcomingProjects(projects =>
      projectKeywordFilter(projects, echoSettings)
    )
  }
}

/** Function to update the array of cement plants
 * @param {Function} setFilteredCementPlants - state setter to update the array of filteredCementPlants
 * @param {Object} echoSettings - the echoSettings atom
 */
export const updateCementPlants = (setFilteredCementPlants, echoSettings) => {
  /** If a country is selected to filter by country, return only divisions or plants located in that country */
  if (echoSettings.countries.length > 0) {
    setFilteredCementPlants(plants =>
      plants.filter(
        cementPlant => cementPlant.country === echoSettings.countries[0]
      )
    )
  }
  /** If a state is selected to filter by state, return only divisions/plants/projects/depots located in that state */
  if (echoSettings.states.length > 0) {
    setFilteredCementPlants(plants =>
      filterEndpointDataByState(plants, echoSettings)
    )
  }
  /** If a city is selected to filter by city, return only plants/projects located within 200 km of that city */
  if (echoSettings.cities.length > 0) {
    setFilteredCementPlants(plants => radiusCityFilter(plants, echoSettings))
  }
}

/** Function to update the array of CO2 depots
 * @param {Function} setFilteredCO2Depots - state setter to update the array of filteredCO2Depots
 * @param {Object} echoSettings - the echoSettings atom
 * @param {Array} addressCoordinates - array of coordinates for the searched address
 */
export const updateCO2Depots = (
  setFilteredCO2Depots,
  echoSettings,
  addressCoordinates
) => {
  /** If a country is selected to filter by country, return only divisions or plants located in that country */
  if (echoSettings.countries.length > 0) {
    setFilteredCO2Depots(depots =>
      depots.filter(depot => depot.country === echoSettings.countries[0])
    )
  }
  /** If a state is selected to filter by state, return only divisions/plants/projects/depots located in that state */
  if (echoSettings.states.length > 0) {
    setFilteredCO2Depots(depots =>
      filterEndpointDataByState(depots, echoSettings)
    )
  }
  /** If a city is selected to filter by city, return only plants/projects located within 200 km of that city */
  if (echoSettings.cities.length > 0) {
    setFilteredCO2Depots(depots => radiusCityFilter(depots, echoSettings))
  }
  /** If an address is searched to filter by address, display only the closest 3 CO2 depots to that address. */
  if (addressCoordinates.length) {
    setFilteredCO2Depots(depots => {
      return depots.filter(depot =>
        depots
          .map(location => {
            return haversineDistance(
              location.latitude,
              location.longitude,
              addressCoordinates[1],
              addressCoordinates[0]
            )
          })
          .sort((a, b) => a - b)
          .slice(0, 3)
          .includes(
            haversineDistance(
              depot.latitude,
              depot.longitude,
              addressCoordinates[1],
              addressCoordinates[0]
            )
          )
      )
    })
  }
}

/** Function to update the array of CarbonCure projects (ongoing or finished)
 * @param {Function} setFilteredCarbonCureProjects - state setter to update the array of filteredOngoingProjects or filteredFinishedProjects
 * @param {Object} echoSettings - the echoSettings atom
 */
export const updateCarbonCureProjects = (
  setFilteredCarbonCureProjects,
  echoSettings
) => {
  /** If a state is selected to filter by state, return only divisions/plants/projects/depots located in that state */
  if (echoSettings.states.length > 0) {
    setFilteredCarbonCureProjects(projects =>
      filterEndpointDataByState(projects, echoSettings)
    )
  }
  /** If a city is selected to filter by city, return only plants/projects located within 200 km of that city */
  if (echoSettings.cities.length > 0) {
    setFilteredCarbonCureProjects(projects =>
      radiusCityFilter(projects, echoSettings)
    )
  }
  /** If a start date is selected, filter out projets with a start date before the selected start date. */
  if (echoSettings.startDate.length > 0) {
    setFilteredCarbonCureProjects(projects =>
      projects.filter(project => echoSettings.startDate <= project.startDate)
    )
  }
  /** If an end date is selected, filter out projects with a start date after the selected end date. */
  if (echoSettings.endDate.length > 0) {
    setFilteredCarbonCureProjects(projects =>
      projects.filter(project => echoSettings.endDate >= project.startDate)
    )
  }
  /** If key words are entered into the keyword filter, return only projects that contain the keyword text. */
  if (echoSettings.keywords.length > 0) {
    setFilteredCarbonCureProjects(projects =>
      projectKeywordFilter(projects, echoSettings)
    )
  }
}

/** Function to update the array of CO2 vendors
 * @param {Function} setFilteredCO2Vendors - state setter to update the array of filteredCO2Vendors
 * @param {Object} echoSettings - the echoSettings atom
 */
export const updateCO2Vendors = (setFilteredCO2Vendors, echoSettings) => {
  /** If a state is selected to filter by state, return only divisions/plants/projects/depots located in that state */
  if (echoSettings.states.length > 0) {
    setFilteredCO2Vendors(vendors =>
      filterEndpointDataByState(vendors, echoSettings)
    )
  }
}

/** Update Echo data and settings according to which filters have been used
 * @param {Array} dataToUpdate - array of data types that need to be updated
 * @param {Object} stateSetters - list of state setters to update the data arrays
 * @param {Object} echoSettings - the echoSettings atom
 * @param {Array} zipCodeCoordinates - array of coordinates for the searched zip code
 * @param {Array} addressCoordinates - array of coordinates for the searched address
 */
export const echoUpdater = (
  dataToUpdate,
  stateSetters,
  echoSettings,
  zipCodeCoordinates,
  addressCoordinates
) => {
  if (dataToUpdate.includes('filteredCorpData')) {
    updateCorpData(
      stateSetters.setFilteredCorpData,
      echoSettings,
      zipCodeCoordinates
    )
  }
  if (dataToUpdate.includes('filteredSalesOpportunities')) {
    updateSalesOpportunities(
      stateSetters.setFilteredSalesOpportunities,
      echoSettings
    )
  }
  if (dataToUpdate.includes('filteredUpcomingProjects')) {
    updateUpcomingProjects(
      stateSetters.setFilteredUpcomingProjects,
      echoSettings
    )
  }
  if (dataToUpdate.includes('filteredCementPlants')) {
    updateCementPlants(stateSetters.setFilteredCementPlants, echoSettings)
  }
  if (dataToUpdate.includes('filteredCO2Depots')) {
    updateCO2Depots(
      stateSetters.setFilteredCO2Depots,
      echoSettings,
      addressCoordinates
    )
  }
  if (dataToUpdate.includes('filteredOngoingProjects')) {
    updateCarbonCureProjects(
      stateSetters.setFilteredOngoingProjects,
      echoSettings
    )
  }
  if (dataToUpdate.includes('filteredFinishedProjects')) {
    updateCarbonCureProjects(
      stateSetters.setFilteredFinishedProjects,
      echoSettings
    )
  }
  if (dataToUpdate.includes('filteredCO2Vendors')) {
    updateCO2Vendors(stateSetters.setFilteredCO2Vendors, echoSettings)
  }
}

/** Check if a plant or project location is within a specified distance (in km) of a selected filter (zip code, city) */
export const checkIsWithinRadius = (
  locationCoordinates,
  filterCoordinates,
  radiusKM
) => {
  const distance = haversineDistance(
    locationCoordinates[1],
    locationCoordinates[0],
    filterCoordinates[1],
    filterCoordinates[0]
  )
  return distance <= radiusKM
}

/** If the CO2 Depots layer is turned off, also turn of the "connect CO2 to customers" layer. Also reset the address search and "Filter by" section
 * @param {Object} echoSettings - the echoSettings atom
 * @param {Function} setEchoSettings - state setter to update the echoSettings atom
 * @param {Function} setFilterGroup - state setter to update the filter group (location filter being used)
 * @param {Function} setAddressOptions - state setter to update the autocomplete options array for the address search
 * @param {Function} setAddressSearch - state setter to update the searched address value
 * @param {Function} setAddressCoordinates - state setter to update the searched address coordinates
 */
export const turnOffCO2Depots = async (
  echoSettings,
  setEchoSettings,
  setFilterGroup,
  setAddressOptions,
  setAddressSearch,
  setAddressCoordinates
) => {
  const newSettings = await cloneDeep(echoSettings)
  newSettings.co2ConnectedToCustomers = false
  newSettings.countries = []
  newSettings.states = []
  newSettings.cities = []
  newSettings.groups = []
  newSettings.companies = []
  newSettings.zipCodes = []
  newSettings.addresses = []
  setEchoSettings(newSettings)
  setFilterGroup('country')
  setAddressOptions([])
  setAddressSearch('')
  setAddressCoordinates([])
}

/** If the project search option is selected, only display customers. Also, disable the salesperson and greenfield features, and reset the Filter By section.
 * @param {Object} echoSettings - the echoSettings atom
 * @param {Function} setEchoSettings - state setter to update the echoSettings atom
 * @param {Function} setFilterGroup - state setter to update the filter group (location filter being used)
 * @param {Function} setSelectedOptions - state setter to update the selected options in the map layer autocomplete
 */
export const activateProjectSearch = (
  echoSettings,
  setEchoSettings,
  setFilterGroup,
  setSelectedOptions
) => {
  const newSettings = cloneDeep(echoSettings)
  newSettings.ownership = ['customer']
  newSettings.salesPeople = []
  newSettings.isGreenfield = false
  newSettings.plantsLimits = []
  newSettings.countries = []
  newSettings.states = []
  newSettings.cities = []
  newSettings.groups = []
  newSettings.companies = []
  newSettings.zipCodes = []
  newSettings.addresses = []
  setEchoSettings(newSettings)
  setFilterGroup('country')
  /** Turn off map layers for CO₂ Depots, Opportunities, and Cement Plants. */
  setSelectedOptions(prevOptions =>
    prevOptions.filter(
      option =>
        option !== 'CO₂ Depots' &&
        option !== 'Cement Plants' &&
        option !== 'Opportunities'
    )
  )
}

/** Boolean that indicates whether the project search feature is active.
 * @param {Object} echoSettings - the echoSettings atom
 * @returns {Boolean} - whether the project search feature is active.
 */
export const isProjectSearchActive = echoSettings => {
  return echoSettings.layers.includes(EMapLayers.projectSearch)
}

/** Boolean that indicates whether CO₂ Depots is selected.
 * @param {Object} echoSettings - the echoSettings atom
 * @returns {Boolean} - whether CO₂ Depots is selected.
 */
export const isCO2DepotsSelected = echoSettings => {
  return echoSettings.layers.includes(EMapLayers.co2Depots)
}
