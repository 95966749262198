import React from 'react'
import PropTypes from 'prop-types'
import { getReadyMixPlantsLayer } from './ReadyMixPlants/ReadyMixPlantsHelper'
import { getPrecastPlantsLayer } from './PrecastPlants/PrecastPlantsHelper'
import { getCementPlantsLayer } from './CementPlants/CementPlantsHelper'
import { getCO2DepotsLayer } from './co2Depots/co2DepotsHelper'
import { getReadyMixCompaniesLayer } from './ReadyMixCompanies/ReadyMixCompaniesHelper'
import { getPrecastCompaniesLayer } from './PrecastCompanies/PrecastCompaniesHelper'
import { getUpcomingProjectsLayer } from './UpcomingProjects/UpcomingProjectsHelper'
import { getCarbonCureProjectsLayer } from './CarbonCureProjects/CarbonCureProjectsHelper'
import { getOpportunitiesLayer } from './Opportunities/OpportunitiesHelper'
import { getZipCodeLayer } from './ZipCodeLayer/ZipCodeHelper'
import { getCO2CustomersLayer } from './co2Customers/co2CustomersHelper'
import ReactMapGL from 'react-map-gl'
import { mapboxApiAccessToken } from '../../Constants/Constants'
import { getTooltip } from './MapComponentHelpers'
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'
import { useRecoilValue, useRecoilState } from 'recoil'
import {
  atomEchoSettings,
  atomFilterBySelected,
  atomPlantMarkers,
  atomMapViewportSettings,
  atomFilteredOngoingProjects,
  atomFilteredFinishedProjects,
} from '../../../Common/echoAtoms'
import DeckGL from '@deck.gl/react'
import { MapView } from '@deck.gl/core'
import { EMapLayers } from '../../Enums/EchoEnums'

const useStyles = makeStyles({
  backdrop: {
    position: 'absolute',
    zIndex: '1200',
  },
})

function MapComponentPresentational(props) {
  MapComponentPresentational.propTypes = {
    /** Array that holds any data table rows that have been selected */
    selectedRows: PropTypes.array.isRequired,
    /** Flat array of companies, used for the map markers */
    companyMarkers: PropTypes.array.isRequired,
    /** Array of filtered Upcoming project data */
    filteredUpcomingProjects: PropTypes.array,
    /** Filtered array of cement plants */
    filteredCementPlants: PropTypes.array,
    /** Coordinates of zip code that was selected in "FilterBy" */
    zipCodeCoordinates: PropTypes.array,
    /** Boolean to display or not display loading spinner */
    isLoading: PropTypes.bool,
    /** Filtered array of CO2 depots */
    filteredCO2Depots: PropTypes.array,
    /** Array of customers and CO2 depots to draw lines between on the map */
    co2CustomerConnections: PropTypes.array,
    /** Filtered array of sales opportunities */
    filteredSalesOpportunities: PropTypes.array,
  }
  /** Atom with Echo Settings **/
  const echoSettings = useRecoilValue(atomEchoSettings)
  const plantMarkers = useRecoilValue(atomPlantMarkers)
  const {
    selectedRows,
    companyMarkers,
    filteredUpcomingProjects,
    filteredCementPlants,
    zipCodeCoordinates,
    isLoading,
    filteredCO2Depots,
    co2CustomerConnections,
    filteredSalesOpportunities,
  } = props

  const classes = useStyles()

  /** Whether or not a "filter by" filter has been selected */
  const filterBySelected = useRecoilValue(atomFilterBySelected)

  /** Atom with filtered ongoing project data */
  const filteredOngoingProjects = useRecoilValue(atomFilteredOngoingProjects)
  /** Atom with filtered finished project data */
  const filteredFinishedProjects = useRecoilValue(atomFilteredFinishedProjects)

  /** Viewport settings for the map */
  const [viewport, setViewport] = useRecoilState(atomMapViewportSettings)

  const layers = [
    ...(echoSettings.zipCodes.length
      ? getZipCodeLayer([{ longLat: zipCodeCoordinates }])
      : []),
    ...(echoSettings.projects.includes('Ongoing Projects')
      ? getCarbonCureProjectsLayer(filteredOngoingProjects, true)
      : []),
    ...(echoSettings.projects.includes('Finished Projects')
      ? getCarbonCureProjectsLayer(filteredFinishedProjects, false)
      : []),
    ...(echoSettings.layers.includes(EMapLayers.opportunities)
      ? getOpportunitiesLayer(filteredSalesOpportunities)
      : []),
    ...(echoSettings.co2ConnectedToCustomers
      ? getCO2CustomersLayer(co2CustomerConnections)
      : []),
    ...(echoSettings.layers.includes(EMapLayers.co2Depots)
      ? getCO2DepotsLayer(filteredCO2Depots, selectedRows)
      : []),
    ...(echoSettings.layers.includes(EMapLayers.precastCompanies)
      ? getPrecastCompaniesLayer(
          echoSettings,
          companyMarkers,
          selectedRows.map(row => row.corporationId),
          filterBySelected
        )
      : []),
    ...(echoSettings.layers.includes(EMapLayers.precastPlants)
      ? getPrecastPlantsLayer(
          echoSettings,
          plantMarkers,
          selectedRows.map(row => row.corporationId),
          filterBySelected
        )
      : []),
    ...(echoSettings.layers.includes(EMapLayers.rmCompanies)
      ? getReadyMixCompaniesLayer(
          echoSettings,
          companyMarkers,
          selectedRows.map(row => row.corporationId),
          filterBySelected
        )
      : []),
    ...(echoSettings.layers.includes(EMapLayers.rmPlants)
      ? getReadyMixPlantsLayer(
          echoSettings,
          plantMarkers,
          selectedRows.map(row => row.corporationId),
          filterBySelected
        )
      : []),
    ...(echoSettings.layers.includes(EMapLayers.cementPlants)
      ? getCementPlantsLayer(filteredCementPlants)
      : []),
    ...(echoSettings.projects.includes('Upcoming Projects')
      ? getUpcomingProjectsLayer(
          filteredUpcomingProjects,
          selectedRows.map(row => row.corporationId)
        )
      : []),
  ]

  return (
    <>
      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <ReactMapGL
        mapboxApiAccessToken={mapboxApiAccessToken}
        {...viewport}
        mapStyle="mapbox://styles/mapbox/light-v10"
        width="100vw"
        height="100vh"
        onViewportChange={setViewport}
      >
        <DeckGL
          layers={layers}
          viewState={viewport}
          getTooltip={({ object }) => getTooltip(object, echoSettings)}
          getCursor={({ isHovering }) => (isHovering ? 'pointer' : 'grab')}
          views={[new MapView({ repeat: true })]}
        />
      </ReactMapGL>
    </>
  )
}

export default MapComponentPresentational
