/* What goes in here?
 * Two things:
 * 1. Methods that support other methods (like the fetchDataHelper, putDataHelper, etc)
 *
 * 2. Any methods that can be used in many places that have to do with fetching, putting, or
 *  posting data that will be applicable between projects (mostly to do with the customers)
 */

/**
 * A helper method to build a URL with query parameters
 * @param {String} endpoint The endpoint you're trying to reach, with the ID and importantly a leading slash
 * @param {Object} params the search parameters you'd like to search on
 * @param {Object} arrayParams The array parameters to be sent in the request
 * @returns {String} URL
 */
function buildURLWithQueryParams(endpoint, params = {}, arrayParams = {}) {
  let arrayString = ''
  if (arrayParams) {
    for (const key in arrayParams) {
      if (arrayParams[key].length !== 0) {
        for (const value in arrayParams[key]) {
          if (Number(arrayParams[key][value]))
            arrayString += `&${key}=${arrayParams[key][value]}`
        }
      }
    }
  }
  const url = new URL([process.env.REACT_APP_KELOWNA, endpoint].join(''))
  const urlSearchParams = new URLSearchParams(params)
  url.search = urlSearchParams + arrayString
  return url
}

/**
 * A helper method to fetch data from Kelowna, retrieves the address of Kelowna from an environment variable
 * defined in the .env file
 *
 * @param {String} endpoint The endpoint you're trying to reach, with the ID and importantly a leading slash
 * @param {Object} params the search parameters you'd like to search on
 * @param {Object} arrayParams The array parameters to be sent in the request
 * @param {AbortController} abortController The AbortController
 * @returns {Promise<Response>}
 */
export async function fetchDataHelper(
  endpoint,
  params = {},
  arrayParams = {},
  abortController = undefined
) {
  return fetch(
    buildURLWithQueryParams(endpoint, params, arrayParams),
    getFetchOptionsObject('GET', abortController)
  ).then(
    res => {
      return res
    },
    // according to the docs you don't want to catch errors, just handle them with a callback
    error => {
      return error
    }
  )
}

export function getHost() {
  return process.env.REACT_APP_KELOWNA
}

/**
 * Helper method to send PUT requests to Kelowna, includes AUTH and aborting a request
 * @param {string} endpoint The endpoint on kelowna that you're trying to reach
 * @param {Object} data Data to be added in the PUT request
 * @param {boolean} dataInUrl Whether the data should be in the URL or the Body of the PUT request, defaults to body (false)
 * @param {AbortController} abortController AbortController to prematurely end the request
 * @returns {Promise<Response>}
 */
export async function putDataHelper(
  endpoint,
  data = {},
  dataInUrl = false,
  abortController = undefined
) {
  const fetchOptionsObj = getFetchOptionsObject('PUT', abortController)
  if (dataInUrl) {
    return fetch(buildURLWithQueryParams(endpoint, data), fetchOptionsObj)
  }
  return fetch([process.env.REACT_APP_KELOWNA, endpoint].join(''), {
    ...fetchOptionsObj,
    body: JSON.stringify(data),
  })
}

/**
 * Helper method to send POST requests to Kelowna, includes AUTH and aborting a request
 * @param {string} endpoint The endpoint on kelowna that you're trying to reach
 * @param {Object} data Data to be added in the POST request
 * @param {boolean} dataInUrl Whether the data should be in the URL or the Body of the POST request, defaults to body (false)
 * @param {AbortController} abortController AbortController to prematurely end the request
 * @returns {Promise<Response>}
 */
export async function postDataHelper(
  endpoint,
  data = {},
  dataInUrl = false,
  abortController = undefined,
  isMultiPartData = false
) {
  const fetchOptionsObj = getFetchOptionsObject(
    'POST',
    abortController,
    isMultiPartData
  )
  if (dataInUrl) {
    return fetch(buildURLWithQueryParams(endpoint, data), fetchOptionsObj)
  }
  return fetch([process.env.REACT_APP_KELOWNA, endpoint].join(''), {
    ...fetchOptionsObj,
    body: isMultiPartData ? data : JSON.stringify(data),
  })
}

/**
 * Function to create consistency and redule line duplication for the creation of fetch options objects
 * @param {'GET' | 'PUT' | 'POST' | 'DELETE'} method The request method
 * @param {AbortController | undefined} abortController An abort controller to get the associated signal for aborting a request
 * @returns {RequestInit}
 */
export function getFetchOptionsObject(
  method,
  abortController,
  isMultiPartData
) {
  const token = localStorage.getItem('JWT')
  const signal = abortController?.signal
  const headers = {
    Authorization: `Bearer ${token}`,
  }
  if ((method === 'PUT' || method === 'POST') && !isMultiPartData) {
    headers['Content-Type'] = 'application/json'
  }
  return {
    method: method,
    signal: signal,
    mode: 'cors',
    headers: headers,
    credentials: 'omit',
  }
}

export async function getAllCorporations() {
  const endpoint = '/Customers/Management/Corporations'
  return dataHelpers.fetchDataHelper(endpoint)
}

export async function getPlant(id) {
  const endpoint = `/Customers/Management/Plants/${id}`
  return dataHelpers.fetchDataHelper(endpoint)
}

export async function getDivision(id) {
  const endpoint = `/Customers/Management/Divisions/${id}`
  return dataHelpers.fetchDataHelper(endpoint)
}

export async function getCorporation(id) {
  const endpoint = `/Customers/Management/Corporations/${id}`
  return dataHelpers.fetchDataHelper(endpoint)
}

export async function getAllGroupNames(clientsOnly) {
  const endpoint = '/Customers/Management/Corporations/Names'
  return dataHelpers.fetchDataHelper(endpoint, { clientsOnly })
}

export async function getGroupPlants(corporationId) {
  const endpoint = '/Customers/Management/Plants'
  return dataHelpers.fetchDataHelper(endpoint, { corporationId })
}

export async function getCorporationNamesAndIds() {
  const endpoint = `/Customers/Management/Corporations/Names`

  return dataHelpers.fetchDataHelper(endpoint)
}

/** Endpoint to get customer names, returns the Division Name and their respective id*/
export async function getDivisionNames(clientsOnly) {
  const endpoint = `/TSS/Divisions/Names`
  return dataHelpers.fetchDataHelper(endpoint, { clientsOnly })
}

/** Returns the plants, filtered by their parent division */
export async function getCustomerPlants(divisionId) {
  const endpoint = `/Customers/Management/Plants/ByDivision/${divisionId}`
  return dataHelpers.fetchDataHelper(endpoint)
}

/** Post a commissioning report */
export async function postCommissionReport(data) {
  const endpoint = `/TSS/CommissioningReports`
  return dataHelpers.postDataHelper(endpoint, data)
}

/** Edit a commissioning report */
export async function putCommissionReport(reportId, data) {
  const endpoint = `/TSS/CommissioningReports/${reportId}`
  return dataHelpers.putDataHelper(endpoint, data)
}

export async function postReportPDF(reportId, data) {
  const endpoint = `/TSS/CommissioningReports/Pdf/${reportId}`
  return dataHelpers.postDataHelper(endpoint, data, false, undefined, true)
}

const dataHelpers = {
  fetchDataHelper,
  getHost,
  getFetchOptionsObject,
  putDataHelper,
  postDataHelper,
  getAllCorporations,
  getPlant,
  getDivision,
  getCorporation,
  getAllGroupNames,
  getGroupPlants,
  getCorporationNamesAndIds,
  getCustomerPlants,
  getDivisionNames,
}

export default dataHelpers
