import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TableViewPresentational from './TableViewPresentational'
import stateList from '../../../Common/assets/statelist.json'

function TableViewLogical(props) {
  TableViewLogical.propTypes = {
    /** Data table rows that have been selected */
    selectedRows: PropTypes.array.isRequired,
    /** State setter function to keep track of which table rows have been selected */
    setSelectedRows: PropTypes.func.isRequired,
    /** Filtered Echo data */
    filteredCorpData: PropTypes.array.isRequired,
    /** State setter to toggle loading spinner */
    setIsLoading: PropTypes.func.isRequired,
    /** Data table page number */
    page: PropTypes.number,
    /** State setter to update data table page */
    setPage: PropTypes.func,
    /** Ascending or descending sort order of data table column */
    order: PropTypes.string,
    /** State setter to update ascending or descending data table sort order */
    setOrder: PropTypes.func,
    /** Name of data table column to sort by */
    orderBy: PropTypes.string,
    /** State setter to update data table column to sort by */
    setOrderBy: PropTypes.func,
    /** Number of data table rows to display */
    rowsPerPage: PropTypes.number,
    /** State setter to update number of data table rows to display */
    setRowsPerPage: PropTypes.func,
    /** Array of filtered upcoming project data */
    filteredUpcomingProjects: PropTypes.array,
    /** Boolean to display or not display loading spinner */
    isLoading: PropTypes.bool,
    /** Filtered array of CO2 vendors */
    filteredCO2Vendors: PropTypes.array,
  }
  const {
    selectedRows,
    setSelectedRows,
    filteredCorpData,
    setIsLoading,
    page,
    setPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    rowsPerPage,
    setRowsPerPage,
    filteredUpcomingProjects,
    isLoading,
    filteredCO2Vendors,
  } = props

  /** Array of data to display on the ready mix table. */
  const [readyMixTableData, setReadyMixTableData] = useState([])
  /** Array of data to display on the precast table. */
  const [precastTableData, setPrecastTableData] = useState([])
  /** Filtered and formatted array of CO2 vendors */
  const [formattedCO2VendorData, setFormattedCO2VendorData] = useState([])

  /** Check if a division has ready mix plants */
  const hasReadyMixPlants = obj => obj.plants.some(plant => !plant.isPrecast)
  /** Check if a division has precast plants */
  const hasPrecastPlants = obj => obj.plants.some(plant => plant.isPrecast)

  useEffect(() => {
    setPrecastTableData(
      filteredCorpData.filter(company =>
        company.divisions.every(hasPrecastPlants)
      )
    )
    setReadyMixTableData(
      filteredCorpData.filter(company =>
        company.divisions.every(hasReadyMixPlants)
      )
    )
  }, [filteredCorpData])

  /** Format the CO2 vendor data for the table (full state name instead of abbreviation) */
  const formatCO2VenderTableData = vendorData => {
    return vendorData.map(vendor => {
      /** If the state on file is an abbreviation, display the full name on the table. */
      let fullStateName
      if (vendor.state && vendor.state.length === 2) {
        fullStateName = stateList[vendor.state]
      } else {
        fullStateName = vendor.state
      }
      return {
        ...vendor,
        fullStateName: fullStateName,
      }
    })
  }
  useEffect(() => {
    setFormattedCO2VendorData(formatCO2VenderTableData(filteredCO2Vendors))
  }, [filteredCO2Vendors])

  return (
    <TableViewPresentational
      readyMixTableData={readyMixTableData}
      precastTableData={precastTableData}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      setIsLoading={setIsLoading}
      page={page}
      setPage={setPage}
      order={order}
      setOrder={setOrder}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      filteredUpcomingProjects={filteredUpcomingProjects}
      isLoading={isLoading}
      formattedCO2VendorData={formattedCO2VendorData}
    />
  )
}

export default TableViewLogical
