import React from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import { makeStyles, Grid } from '@material-ui/core'
import ViewDesignPage from '../TSS/Views/ViewDesignPage'
import AddDataView from '../TSS/Views/AddDataView'
import AddMixDesignView from '../TSS/Views/AddMixDesignView'
import AddBatchView from '../TSS/Views/AddBatchView'
import { Helmet } from 'react-helmet'
import MaterialManagerView from '../TSS/Views/MaterialManagerView'
import BaleenView from '../TSS/Views/BaleenView'

const useStyles = makeStyles({
  containerOffset: {
    marginTop: '2.6em',
  },
})

function ConcreteHome() {
  const classes = useStyles()
  let { path } = useRouteMatch()

  return (
    <>
      <Helmet>
        <title>TSS</title>
      </Helmet>
      <Grid container justify="center" className={classes.containerOffset}>
        <Grid item xs={12} sm={11} md={10} xl={9}>
          <Switch>
            <Route
              exact
              path={path}
              render={() => <Redirect to={`${path}/AddData`} />}
            />
            <Route exact path={`${path}/Designs`}>
              <Helmet>
                <title>Analysis</title>
              </Helmet>
              <ViewDesignPage />
            </Route>
            <Route path={`${path}/AddData/AddMixDesign`}>
              {/* The title will be set within this specific view, as it will be variable based on editing or adding */}
              <AddMixDesignView />
            </Route>
            <Route exact path={`${path}/AddData/AddBatchData`}>
              {/* The title will be set within this specific view, as it will be variable based on editing or adding */}
              <AddBatchView />
            </Route>
            <Route path={`${path}/AddData`}>
              <Helmet>
                <title>Mix Designs</title>
              </Helmet>
              <AddDataView />
            </Route>
            <Route path={`${path}/MaterialManager`}>
              <Helmet>
                <title>Material Manager</title>
              </Helmet>
              <MaterialManagerView />
            </Route>
            <Route path={`${path}/Baleen`}>
              <Helmet>
                <title>Baleen</title>
              </Helmet>
              <BaleenView />
            </Route>
          </Switch>
        </Grid>
      </Grid>
    </>
  )
}

export default ConcreteHome
