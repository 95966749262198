import { IconLayer } from '@deck.gl/layers'
import { projectColors } from '../../../Constants/Constants'

const getIconSize = (selectedIds, constructionProjectId) => {
  if (selectedIds.includes(constructionProjectId)) {
    return 12
  } else {
    return 6
  }
}

const ICON_MAPPING = {
  marker: { x: 0, y: 0, width: 128, height: 128, mask: true },
}

/**
 * @param {'specified' | 'unspecified'} type
 * @param {'specifiedProjects' | 'unspecifiedProjects'} data
 * @param {Number[]} selectedIds
 * @returns
 */
export function getLayer(type, data, selectedIds) {
  return new IconLayer({
    id: 'upcomingProjects' + type,
    data: data.map(project => ({ ...project, layerType: 'upcomingProject' })),
    pickable: true,
    iconAtlas: 'https://img.icons8.com/material/50/000000/marker--v1.png',
    iconMapping: ICON_MAPPING,
    getIcon: project => 'marker',
    sizeScale: 15,
    getPosition: project => [project.longitude, project.latitude],
    getSize: project => getIconSize(selectedIds, project.constructionProjectId),
    getColor: projectColors[type].rgba,
  })
}

export function getUpcomingProjectsLayer(
  filteredUpcomingProjects,
  selectedIds
) {
  /** Projects with CarbonCure specified */
  const specifiedProjects = filteredUpcomingProjects.filter(
    project => project.isCcSpecified
  )
  /** Projects with CarbonCure not specified */
  const unspecifiedProjects = filteredUpcomingProjects.filter(
    project => !project.isCcSpecified
  )

  return [
    getLayer('specified', specifiedProjects, selectedIds),
    getLayer('unspecified', unspecifiedProjects, selectedIds),
  ]
}
