import React, { useState, useEffect } from 'react'

export async function fetchQuicksightEmbedURL(qsURL, qsParams = {}) {
  const token = localStorage.getItem('JWT')
  const options = {
    mode: 'cors',
    headers: {
      Authorization: `${token}`,
    },
  }
  const url = new URL(qsURL)
  const urlSearchParams = new URLSearchParams(qsParams)
  url.search = urlSearchParams
  try {
    let res = await fetch(url, options)
    if (!res.ok) {
      return '<html><p>ERROR!<p><html>'
    }
    let resMetaData = await res.json()
    return resMetaData.EmbedUrl
  } catch (err) {
    // TODO: Return srcDoc HTML variable
    console.log('Fetch error:' + err)
  }
}

export default function QuicksightDashboard(props) {
  const [quicksightHTML, setQuicksightHTML] = useState('')
  const { dashboard } = props

  const invokeUrl = dashboard.baseUrl
  const urlParams = {
    dashboardId: dashboard.dashboardId,
    dashboardName: dashboard.dashboardName,
    dashboardRegion: dashboard.dashboardRegion,
  }

  useEffect(() => {
    const getQuicksightEmbedHTML = async () => {
      const embeddedHTML = await fetchQuicksightEmbedURL(invokeUrl, urlParams)
      if (embeddedHTML) {
        setQuicksightHTML(embeddedHTML)
      }
    }
    getQuicksightEmbedHTML()
  }, [props]) // eslint-disable-line react-hooks/exhaustive-deps

  // TODO: Set up conditional rendering with srcDoc HTML that explains that the Embed URL couldn't be fetched.
  return (
    <iframe
      src={quicksightHTML}
      style={{ width: '100%', height: '1500px' }}
      title={dashboard.title}
    />
  )
}
