import React from 'react'
import { makeStyles, Typography, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { EMapLayers } from '../../../Enums/EchoEnums'
import { echoColors } from '../../../../theme/colors'
import typography from '../../../../theme/typography'

const useStyles = makeStyles({
  legendTypeLabel: {
    fontWeight: typography.fontWeight.bold,
  },
  legendItemLabel: {
    fontSize: typography.fontSize.S,
  },
  icon: {
    margin: '8px 0px',
    fontSize: typography.fontSize.XL,
  },
  opportunitiesColor: {
    color: echoColors.opportunities.hex,
  },
})

/** Function to return sales opportunities icon for the map legend */
function OpportunitiesLegend(props) {
  OpportunitiesLegend.propTypes = {
    /** The Echo settings atom */
    echoSettings: PropTypes.object.isRequired,
  }
  const { echoSettings } = props
  const classes = useStyles()
  if (echoSettings.layers.includes(EMapLayers.opportunities)) {
    return (
      <Grid container item lg={12}>
        <Grid item container lg={3} direction="column" justify="center">
          <Typography className={classes.legendTypeLabel}>
            Opportunities:
          </Typography>
        </Grid>
        <Grid item container lg={9}>
          <Grid container item lg={4}>
            <Grid item lg={2}>
              <FiberManualRecordIcon
                className={`${classes.icon} ${classes.opportunitiesColor}`}
              />
            </Grid>
            <Grid container item direction="column" lg={10} justify="center">
              <Typography className={classes.legendItemLabel}>
                Opportunities
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  } else return null
}

export default OpportunitiesLegend
