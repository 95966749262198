import React from 'react'
import { makeStyles, Typography, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import RoomIcon from '@material-ui/icons/Room'
import { projectColors } from '../../../Constants/Constants'

const useStyles = makeStyles({
  legendItemLabel: {
    fontSize: '0.9rem',
  },
  legendTypeLabel: {
    fontWeight: 'bold',
  },
  icon: {
    margin: '8px 0px',
    fontSize: '1.6rem',
  },
  specifiedColor: {
    color: projectColors.specified.hex,
  },
  unspecifiedColor: {
    color: projectColors.unspecified.hex,
  },
})

/** Function to return UpcomingProject icons for the map legend */
function UpcomingProjectsLegend(props) {
  UpcomingProjectsLegend.propTypes = {
    /** The Echo settings atom */
    echoSettings: PropTypes.object.isRequired,
  }
  const { echoSettings } = props
  const classes = useStyles()
  if (echoSettings.projects.includes('Upcoming Projects')) {
    return (
      <Grid container item lg={12}>
        <Grid item container lg={3} direction="column" justify="center">
          <Typography className={classes.legendTypeLabel}>
            Upcoming Projects:
          </Typography>
        </Grid>
        <Grid item container lg={9}>
          <Grid container item lg={4}>
            <Grid item lg={2}>
              <RoomIcon
                className={`${classes.icon} ${classes.specifiedColor}`}
              />
            </Grid>
            <Grid container item direction="column" lg={10} justify="center">
              <Typography className={classes.legendItemLabel}>
                CCT specified
              </Typography>
            </Grid>
          </Grid>
          <Grid container item lg={4}>
            <Grid item lg={2}>
              <RoomIcon
                className={`${classes.icon} ${classes.unspecifiedColor}`}
              />
            </Grid>
            <Grid container item direction="column" lg={10} justify="center">
              <Typography className={classes.legendItemLabel}>
                CCT not specified
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  } else return null
}

export default UpcomingProjectsLegend
