import dataHelpers from '../../Common/Helpers/DataHelpers'
import { std, mean } from 'mathjs'
import { histoColorSet } from '../Constants/AnalysisConstants'
import cloneDeep from 'lodash.clonedeep'
import { cementTypes, plantDataColumns } from '../Constants/AddDataConstants'
import { histogramColor } from '../Constants/CommissionReportConstants'

//////////////////////
// HTTP GET METHODS //
//////////////////////
/**
 *
 * @param {AbortController} abortController
 */
export async function getDivisionsOverview(abortController) {
  const endpoint = `/TSS/Divisions/Overview`
  return dataHelpers.fetchDataHelper(endpoint, {}, {}, abortController)
}

/**
 *
 * @param {Object} parameters
 * @param {AbortController} abortController
 */
export async function getLatestBatchTestSamples(
  parameters = {},
  abortController = undefined
) {
  const endpoint = `/TSS/BatchTestSamples/BatchTestsOverviewRowCount`
  return dataHelpers.fetchDataHelper(endpoint, parameters, {}, abortController)
}

/**
 *
 * @param {Object} constraints
 * @param {Object} arrayParams
 * @param {AbortController}
 * @returns
 */
export async function getMixDesignByBatch(
  constraints,
  arrayParams,
  abortController
) {
  const endpoint = `/TSS/MixDesigns/BatchByMixRowCount`
  return dataHelpers.fetchDataHelper(
    endpoint,
    constraints,
    arrayParams,
    abortController
  )
}

/**
 * Function to get mix groups
 * @param {Object} constraints
 * @param {Object} arrayParams
 * @returns {Promise<Response>} Response from kelowna
 */
export async function getMixGroupByBatch(
  constraints,
  arrayParams,
  abortController
) {
  const endpoint = `/TSS/MixDesigns/MixGroup`
  return dataHelpers.fetchDataHelper(
    endpoint,
    constraints,
    arrayParams,
    abortController
  )
}

/** Returns mix designs and their variations */
export async function getMixGroupByPlant(plantId) {
  const endpoint = `/TSS/MixDesigns/MixGroupsByPlant/${plantId}`
  return dataHelpers.fetchDataHelper(endpoint)
}

/**
 * Function to get variation samples for a given variation
 * @param {Object} constraints
 * @returns {Promise<Response>} Response from kelowna
 */
export async function getMixVariationSamples(constraints) {
  const endpoint = `/TSS/MixDesigns/MixVariationSamples`
  return dataHelpers.fetchDataHelper(endpoint, constraints)
}

export async function getPlantMixDesigns(plantId) {
  const endpoint = '/TSS/MixDesigns'
  return dataHelpers.fetchDataHelper(endpoint, { plantId })
}

export async function getMixGroupDetails(mixDesignId) {
  const endpoint = `/TSS/MixDesigns/MixGroupDetails/${mixDesignId}`
  return dataHelpers.fetchDataHelper(endpoint)
}

export async function getDivisionMixCodesAndIds(divisionId) {
  const endpoint = `/TSS/MixDesigns/MixCodesByDivisionId/${divisionId}`
  return dataHelpers.fetchDataHelper(endpoint)
}

export async function getMixDesignWithIngredientsById(mixDesignId) {
  const endpoint = `/TSS/MixDesigns/MixDesignWithIngredients/${mixDesignId}`
  return dataHelpers.fetchDataHelper(endpoint)
}

export async function getSupplierNames() {
  const endpoint = '/TSS/CementPlants/Names'
  return dataHelpers.fetchDataHelper(endpoint)
}

export async function getBatchTestSamplesByMixDesignId(mixDesignId) {
  const endpoint = `/TSS/BatchTestSamples/ByMixId/${mixDesignId}`
  return dataHelpers.fetchDataHelper(endpoint)
}

export async function getAssociatedMixesByMixDesignId(options) {
  const endpoint = `/TSS/MixDesigns/AssociatedMixes`
  return dataHelpers.fetchDataHelper(endpoint, options)
}

export async function getMixDetailsByMixDesignId(options) {
  const endpoint = `/TSS/MixDesigns/id`
  return dataHelpers.fetchDataHelper(endpoint, options)
}

export async function getTssReps(abortController) {
  const endpoint = `/Customers/Management/Corporations/TssReps`
  return dataHelpers.fetchDataHelper(endpoint, {}, {}, abortController)
}

export async function getMaterialMetadata() {
  const endpoint = '/TSS/MaterialManager/Metadata'
  return dataHelpers.fetchDataHelper(endpoint)
}

export async function getMaterialsByDivisionId(divisionId) {
  const endpoint = `/TSS/MaterialManager/ByDivision/${divisionId}`
  return dataHelpers.fetchDataHelper(endpoint)
}

export async function getPlantCompositionsByDivisionId(options) {
  const endpoint = `/TSS/MaterialManager/PlantCements/`
  return dataHelpers.fetchDataHelper(endpoint, options)
}

export async function getCommissionReportSettingsByReportId(reportId) {
  const endpoint = `/TSS/CommissioningReports/${reportId}`
  return dataHelpers.fetchDataHelper(endpoint)
}

export async function getCompanyLogoByDivisionId(divisionId) {
  const endpoint = `/TSS/CommissioningReports/CustomerLogo/${divisionId}`
  return dataHelpers.fetchDataHelper(endpoint)
}

export async function getCommissionReportPDFByReportId(reportId) {
  const endpoint = `/TSS/CommissioningReports/Pdf/${reportId}`
  return dataHelpers.fetchDataHelper(endpoint)
}
/**
 *
 * @param {AbortController} abortController
 */
export async function getFilterOptions(abortController) {
  const endpoint = `/Customers/Management/Plants/FilterOptions`
  return dataHelpers.fetchDataHelper(endpoint, {}, {}, abortController)
}

/**
 * Function to get the corporation hierarchy from kelowna
 * @param {Number} corporationId
 * @param {Boolean} onlyClients
 * @returns {Promise<Response>} Response from kelowna
 */
export async function getCorporationHierarchy(corporationId, onlyClients) {
  const endpoint = `/Customers/Management/Corporations/Hierarchy/${corporationId}`
  const options = { onlyClients }
  return dataHelpers.fetchDataHelper(endpoint, options)
}

/**
 * Function to get materials
 * @param {Object} constraints
 * @param {Object} arrayParams
 * @returns {Promise<Response>} Response from kelowna
 */
export async function getMaterialByBatch(
  constraints,
  arrayParams,
  abortController
) {
  const endpoint = `/TSS/MaterialManager/Table`
  return dataHelpers.fetchDataHelper(
    endpoint,
    constraints,
    arrayParams,
    abortController
  )
}

/**
 * Function to get baleen variations and corresponding samples
 * @param {Object} mixDesignId
 * @param {Object} constraints
 * @param {Object} arrayParams
 * @param {Object} abortController
 * @returns {Promise<Response>} Response from kelowna
 */
export async function getMixGroupWithSamples(
  mixDesignId,
  constraints,
  arrayParams,
  abortController
) {
  const endpoint = `/TSS/MixDesigns/MixGroupWithSamples/${mixDesignId}`
  return dataHelpers.fetchDataHelper(
    endpoint,
    constraints,
    arrayParams,
    abortController
  )
}

/**
 * Function to get reports for report library
 * @param {Object} constraints
 * @param {Object} arrayParams
 * @param {Object} abortController
 * @returns {Promise<Response>} Response from kelowna
 */
export async function getReports(constraints, arrayParams, abortController) {
  const endpoint = `/TSS/CommissioningReports/ReportLibrary`
  return dataHelpers.fetchDataHelper(
    endpoint,
    constraints,
    arrayParams,
    abortController
  )
}

/**
 * Function to get mix group options based on selected division
 * @param {Number} divisionId
 * @param {Object} constraints
 * @param {Object} arrayParams
 * @param {Object} abortController
 * @returns {Promise<Response>} Response from kelowna
 */
export async function getReportMixGroupsByDivision(divisionId, constraints) {
  const endpoint = `/TSS/CommissioningReports/MixGroups/${divisionId}`
  return dataHelpers.fetchDataHelper(endpoint, constraints)
}

////////////////////////
// HTTP POST METHODS //
///////////////////////

export async function postNewBatch(newBatches) {
  const endpoint = '/TSS/BatchTestSamples/AddBatchData'
  return dataHelpers.postDataHelper(endpoint, newBatches)
}

export async function postNewMixDesign(newDesign) {
  const endpoint = '/TSS/MixDesigns'
  return dataHelpers.postDataHelper(endpoint, newDesign)
}

/**
 *
 * @param {Object} material The data for the new material
 * @param {Object} parameters Optional URL Parameters. Used for updating an existing material's alias.
 * e.g. { materialMappingId: 100, aliasName: 'Alias' }
 * @returns
 */
export async function postNewMaterial(material, parameters = {}) {
  let endpoint = '/TSS/MaterialManager/Material'
  let paramMapId = parameters.materialMappingId
  let paramAliasName = parameters.aliasName
  // Check if the 'materialMappingId' and/or 'aliasName' properties exist in the parameters object
  if (paramMapId && paramAliasName) {
    endpoint += `?materialMappingId=${encodeURIComponent(
      paramMapId
    )}&aliasName=${encodeURIComponent(paramAliasName)}`
  } else if (paramMapId) {
    endpoint += `?materialMappingId=${encodeURIComponent(paramMapId)}`
  } else if (paramAliasName) {
    endpoint += `?aliasName=${encodeURIComponent(paramAliasName)}`
  }
  return dataHelpers.postDataHelper(endpoint, material)
}

/**
 *
 * @param {Object} batchTestSampleIds
 * @param {AbortController} abortController
 * @returns
 */
export async function fetchBatchesByBatchTestSampleIds(
  batchTestSampleIds,
  abortController
) {
  const endpoint = '/TSS/BatchTestSamples/BatchTestSamplesByIds'
  return dataHelpers.postDataHelper(
    endpoint,
    batchTestSampleIds,
    false,
    abortController
  )
}

/**
 * Function to get the corporation hierarchy from kelowna
 * @param {} offset
 * @param {} limit
 * @param {} sortColumn
 * @param {} sortOrder
 * @param {} filterSettings
 * @returns {Promise<Response>} Response from kelowna
 */
export async function getEditableTablePlantData(
  offset,
  limit,
  sortColumn,
  sortOrder,
  filterSettings
) {
  const endpoint = '/Customers/Management/Plants/Table'
  const data = {
    tableArgs: {
      offset,
      limit,
      sortColumn,
      sortOrder,
    },
    filterSettings,
  }
  return dataHelpers.postDataHelper(endpoint, data)
}

export async function updatePlant(plantData) {
  const endpoint = '/Customers/Management/Plants'
  return dataHelpers.postDataHelper(endpoint, plantData)
}

//////////////////////
// HTTP PUT METHODS //
//////////////////////
export async function putUpdateMixDesign(mixDesign) {
  const endpoint = '/TSS/MixDesigns'
  return dataHelpers.putDataHelper(endpoint, mixDesign)
}

export async function putUpdateBatch(mixDesign) {
  const endpoint = '/TSS/BatchTestSamples/EditBatchData'
  return dataHelpers.putDataHelper(endpoint, mixDesign)
}

export async function putUpdateOutliers(samples) {
  const endpoint = '/TSS/BatchTestSamples/Outliers'
  return dataHelpers.putDataHelper(endpoint, samples)
}

export async function putUpdateMaterial(material, parameters = {}) {
  let endpoint = '/TSS/MaterialManager/Material'
  let paramMapId = parameters.materialMappingId
  let paramAliasName = parameters.aliasName
  // Check if the 'materialMappingId' and/or 'aliasName' properties exist in the parameters object
  if (paramMapId && paramAliasName) {
    endpoint += `?materialMappingId=${encodeURIComponent(
      paramMapId
    )}&aliasName=${encodeURIComponent(paramAliasName)}`
  } else if (paramMapId) {
    endpoint += `?materialMappingId=${encodeURIComponent(paramMapId)}`
  } else if (paramAliasName) {
    endpoint += `?aliasName=${encodeURIComponent(paramAliasName)}`
  }
  return dataHelpers.putDataHelper(endpoint, material)
}

export async function putUpdateMaterialAlias(material) {
  const endpoint = '/TSS/MaterialManager/Alias'
  return dataHelpers.putDataHelper(endpoint, material)
}

export async function putMergeMaterial(source, target) {
  const endpoint = `/TSS/MaterialManager/Merge?sourceMappingId=${source}&targetMappingId=${target}`
  return dataHelpers.putDataHelper(endpoint)
}

/**
 * function to process data and generate warning messages
 * @param {Object} mixDesign the currently selected mix design
 * @param {Array} messages the warning message array to be appended
 * @returns {Array} messagesForMix array with selected mix designs warnings
 */
export function displayWarningMessages(mixDesign, messages) {
  let messagesForMix = [...messages]
  if (mixDesign.standardDeviation === 0) {
    messagesForMix.push(
      'The chart could not be generated for ' +
        mixDesign.mixCode +
        ', because the standard deviation for strength readings at 28 days is 0'
    )
  } else if (mixDesign.totalSamples28Days <= 4) {
    messagesForMix.push(
      'The chart may be distorted for ' +
        mixDesign.mixCode +
        ', because only ' +
        mixDesign.totalSamples28Days +
        ' sample(s) are available for 28 day interval.'
    )
  }
  return messagesForMix
}

export async function putUpdateCommissioning(divisionId) {
  const endpoint = `/TSS/Divisions/Commissioning/${divisionId}`
  return dataHelpers.putDataHelper(endpoint, divisionId)
}

//////////////////////
// CHART METHODS   //
//////////////////////

/**
 * function get histogram series for Highcharts
 * @param {Array} data strengths array
 * @param {Array} name series name that will be displayed
 * @param {Array} index of the selected mix
 * @param {boolean} isCommissionReport - Flag indicating if it's a commission report.
 * @param {boolean} showBellCurve - Flag indicating whether to show the histogram.
 * @returns {Array} for Highcharts
 */
export function getHistogram(
  data,
  name,
  index,
  isCommissionReport,
  showHistogram
) {
  return [
    {
      visible: false,
      type: 'scatter',
      data,
      id: name,
      zIndex: -3,
    },
    {
      legendIndex: index * 3 + 1,
      name: name,
      type: 'histogram',
      baseSeries: name,
      binsNumber: 'square-root',
      borderColor: isCommissionReport
        ? histogramColor[index].dark
        : histoColorSet[index % histoColorSet.length].dark,
      color: isCommissionReport
        ? histogramColor[index].light
        : histoColorSet[index % histoColorSet.length].light,
      yAxis: 1,
      zIndex: -1,
      showInLegend: !isCommissionReport,
      visible: showHistogram ?? true,
    },
  ]
}

/**
 * function get failure zone series for Highcharts
 * @param {Array} data strengths array
 * @param {Array} name series name that will be displayed
 * @param {Array} index of the selected mix
 * @param {Array} failureCoords [x,y] of the end of the failure zone
 * @param {Number} designStrengthInterval the specified design strength interval of the mix design
 * @param {Number} selectedAge the value of the age filter selected by the user
 * @param {boolean} isCommissionReport - Flag indicating if it's a commission report.
 * @param {boolean} showBellCurve - Flag indicating whether to show the failure zone.
 * @returns {Array} for Highcharts
 */
export function getFailureZone(
  data,
  name,
  index,
  failureCoords,
  designStrengthInterval,
  selectedAge,
  showFailureZone
) {
  //get coords under the failure zone
  const failureData = cloneDeep(data.filter(x => x[0] <= failureCoords[0]))
  //add last coord to complete the spline at the correct point
  failureData.push(failureCoords)
  const isCommissionReport = showFailureZone !== undefined
  const visible =
    designStrengthInterval === selectedAge &&
    (isCommissionReport ? showFailureZone : true)

  return [
    {
      legendIndex: index * 3 + 3,
      name,
      type: 'areaspline',
      data: failureData,
      color: isCommissionReport
        ? histogramColor[index % histoColorSet.length].dark + '60'
        : histoColorSet[index % histoColorSet.length].dark + '60',
      marker: {
        enabled: false,
      },
      enableMouseTracking: true,
      showInLegend: visible && !isCommissionReport,
      visible,
    },
  ]
}

/**
 * function get bell curve series for Highcharts
 * @param {Array} data strengths array
 * @param {Array} name series name that will be displayed
 * @param {Array} index of the selected mix
 * @param {boolean} isCommissionReport - Flag indicating if it's a commission report.
 * @param {boolean} showBellCurve - Flag indicating whether to show the bell curve.
 * @returns {Array} for Highcharts
 */
export function getBellCurve(
  data,
  name,
  index,
  isCommissionReport,
  showBellCurve
) {
  return [
    {
      legendIndex: index * 3 + 2,
      name,
      type: 'spline',
      data,
      color: isCommissionReport
        ? histogramColor[index % histogramColor.length].dark
        : histoColorSet[index % histoColorSet.length].dark,
      marker: {
        enabled: false,
      },
      showInLegend: !isCommissionReport,
      visible: showBellCurve ?? true,
    },
  ]
}

/**
 * function get vertical line series for Highcharts
 * @param {Array} data strengths array
 * @param {Array} sdLinesXValues where the vertical lines appear on x axis
 * @param {Array} index of the selected mix *
 * @param {boolean} isCommissionReport - Flag indicating if it's a commission report.
 * @param {boolean} showBellCurve - Flag indicating whether to show the vertical lines.
 * @returns {Array} for Highcharts
 */
export function getVerticalLines(
  data,
  sdLinesXValues,
  index,
  isCommissionReport,
  showBellCurve
) {
  let result = []
  data.forEach(point => {
    if (sdLinesXValues.includes(point[0])) {
      result.push({
        type: 'spline',
        showInLegend: false,
        visible: showBellCurve ?? true,
        data: [
          [point[0], 0],
          [point[0], point[1]],
        ],
        color: isCommissionReport
          ? histogramColor[index % histogramColor.length].dark
          : histoColorSet[index % histoColorSet.length].dark,
        marker: {
          enabled: false,
        },
        enableMouseTracking: false,
      })
    }
  })
  return result
}

/**
 * function get norm
 * @param {Number} positionX
 * @param {Number} dataMean
 * @param {Number} stdDev
 * @returns {Number}
 */
export function getNorm(positionX, dataMean, stdDev) {
  let zScore = (positionX - dataMean) / stdDev
  return (
    (1 / (Math.SQRT2 * Math.PI * stdDev * stdDev)) *
    Math.exp(-((zScore * zScore) / 2))
  )
}

/**
 * function get Normalized Array
 * @param {Array} xValues mix strengths, 1D
 * @param {Number} dataMean
 * @param {Number} stdDev
 * @returns {Array} normalizedArray 2D[xValue, norm]
 */
export function getNormalizedArray(xValues, dataMean, stdDev) {
  const normalizedArray = []
  xValues.forEach(positionX => {
    normalizedArray.push([positionX, getNorm(positionX, dataMean, stdDev)])
  })
  return normalizedArray
}

/**
 * function get Strengths
 * @param {Object} record with freshProperties property
 * @returns {Array} strengths
 */
export function getStrengths(record, selectedAge, isCommissionReport) {
  const result = []
  if (isCommissionReport) {
    record.filteredSamples.forEach(sample => {
      if (sample.strengths[selectedAge])
        result.push(sample.strengths[selectedAge])
    })
  } else {
    record.freshProperties.forEach(freshProperty => {
      if (freshProperty.batchStrength !== null) {
        result.push(Number(freshProperty.batchStrength))
      }
    })
  }
  return result
}

/**
 * function get GraphData for Highcharts containing all the required series for the histogram chart
 * @param {Array} mixDesignArray
 * @returns {Array} with Highchart series
 */
export function getGraphData(mixDesignArray, selectedAge, helperVariables) {
  const isCommissionReport = helperVariables !== undefined
  const { designStrength, strengthIntervalHours, graphSettings } =
    helperVariables || {}

  let sdRange, minSD, initialNum, stdDev, dataMean, failureCoords
  let xValues = []
  let finalData = []
  let strengths = []
  const expectedSDLines = [0] // draw mean
  let sdLinesXValues = [] // X positions of the std lines we want to display
  let messages = []

  mixDesignArray.forEach((selected, index) => {
    strengths = getStrengths(selected, selectedAge, isCommissionReport)

    /* The Bell Curve can/should not be generated for limited samples or duplicate samples*/
    if (!isCommissionReport)
      messages = displayWarningMessages(selected, messages)

    if (strengths.length > 0) {
      xValues = []
      sdRange = 3
      minSD = -sdRange
      initialNum = 0
      stdDev = isCommissionReport
        ? selected.strengths[selectedAge]?.stDev
        : std(strengths)
      dataMean = isCommissionReport
        ? selected.strengths[selectedAge]?.strength
        : mean(strengths)
      failureCoords = [
        isCommissionReport ? designStrength : selected.designStrength,
        getNorm(
          isCommissionReport ? designStrength : selected.designStrength,
          dataMean,
          stdDev
        ),
      ]

      /* Bell curve logic that begins with -3SD by default. After that it increases by
      0.1 to the min standard deviation*/
      initialNum = dataMean - sdRange * stdDev
      xValues.push(initialNum)
      while (minSD <= sdRange) {
        initialNum += stdDev / 10
        xValues.push(initialNum)
        minSD = minSD + 0.1
        minSD = Number?.(minSD.toFixed(1))

        /* Store the X axis of the SD and mean lines for later use */
        expectedSDLines.includes(minSD) && sdLinesXValues.push(initialNum)
      }

      //normalizedArray
      const normalizedArray = getNormalizedArray(xValues, dataMean, stdDev)
      //vertical lines
      const verticalLines = getVerticalLines(
        normalizedArray,
        sdLinesXValues,
        index,
        isCommissionReport,
        graphSettings?.showBellCurve
      )
      finalData.push(...verticalLines)

      //bell curve
      const bellcurve = getBellCurve(
        normalizedArray,
        selected.variationIdLabel + ' Normal Distribution',
        index,
        isCommissionReport,
        graphSettings?.showBellCurve
      )
      finalData.push(...bellcurve)

      //histogram
      const histo = getHistogram(
        strengths,
        selected.variationIdLabel,
        index,
        isCommissionReport,
        graphSettings?.showHistogram
      )

      finalData.push(...histo)

      //failure
      const designStrengthInterval = isCommissionReport
        ? Number(strengthIntervalHours)
        : Number(selected.interval.replace(/\D/g, ''))
      const failure = getFailureZone(
        normalizedArray,
        selected.variationIdLabel + ' Failure Zone',
        index,
        failureCoords,
        designStrengthInterval,
        selectedAge,
        graphSettings?.showFailureZone
      )

      finalData.push(...failure)
    }
  })
  return finalData
}

/**
 * function prepFilterOptions
 * @param {Array} results from BE with filter options
 * @param {Object} plantsFiltersOptions atom that will be updated
 * @returns {Object} newOptions
 */
export function prepFilterOptions(results, plantsFiltersOptions) {
  const newOptions = cloneDeep(plantsFiltersOptions)
  newOptions.tse = results.tseList.map(x => ({ name: x, id: x }))
  newOptions.customer = results.divisions.map(x => ({
    name: x.name,
    id: x.divisionId.toString(),
  }))
  newOptions.state = results.plantStates.map(x => ({ name: x, id: x }))
  newOptions.cementPlants = results.cementPlants.map(x => ({
    name: x.name,
    id: String(x.cementPlantId),
  }))
  newOptions.cementType = generateFilterOptionsFromObject(cementTypes)
  newOptions.missingInformation = generateFilterOptionsFromObject(
    plantDataColumns
  )

  return newOptions
}
/**
 * Get abbrivated names for certain names used in the editable plants table filtering
 * @param {string} name
 * @returns {string}
 */
const getAbbreviatedNames = name => {
  switch (name) {
    case 'Avg Cement Loading':
      return 'Avg Cmt Load'
    case 'Cement Reduction':
      return 'Cmt. Red'
    case 'Avg Yards / Truck':
      return 'Avg Yd. / Truck'
    case 'Cement Supply':
      return 'Cmt. Supply'
    default:
      return name
  }
}
/**
 * Create array with name and id used in editable plants table filtering.
 * @param {Object} object
 * @returns {Object[]}
 */
const generateFilterOptionsFromObject = object => {
  const array = []
  for (const property in object) {
    array.push({ name: getAbbreviatedNames(object[property]), id: property })
  }
  return array
}

/**
 * function getUrlFromFilters
 * @param {Object} settings some settings atom
 * @param {String} segment initial segment of url
 * @returns {String} url
 */
export function getUrlFromFilters(settings, url) {
  let sign = '?'

  function loopValues(values, setting) {
    values.forEach((_, i) => {
      url = url + sign + setting + '=' + values[i]
      sign = '&'
    })
  }

  for (const setting in settings) {
    const values = settings[setting]
    if (typeof values === 'boolean') {
      loopValues([values], setting)
    } else if (values) {
      loopValues(values, setting)
    }
  }

  return url
}

/**
 * function getNewSettings
 * @param {Object} filterSettings some plant filter settings atom
 * @param {Object} params a URLSearchParams object instance
 * @returns {Object} updated plant filter settings
 */
export function getNewSettings(filtersSettings, params) {
  const newSettings = cloneDeep(filtersSettings)

  for (const setting in filtersSettings) {
    if (params.getAll(setting).length > 0) {
      if (typeof filtersSettings[setting] === 'boolean') {
        newSettings[setting] = params.get(setting) === 'true'
      } else {
        newSettings[setting] = params.getAll(setting)
      }
    }
  }
  return newSettings
}
